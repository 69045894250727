// @flow strict

import firebase from 'firebase';

import type { RouteType, LanguageCodeConstant, RoleConstant } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import { ROLES, ROUTES } from './constants';
import { DEFAULT_LOCALE } from 'services/Language/actions';

import { firebaseConfig, appEnvironment } from 'env';

// Authentication related utility functions

export const getFirebaseAuth = () => {
    const firebaseAuth = firebase.auth();
    if (appEnvironment === 'local') {
        const emulatorUrl = firebaseConfig.authEmulatorUrl;
        console.log('Firebase Auth created in local using emulator url: ', emulatorUrl);
        firebaseAuth.useEmulator(emulatorUrl);
    }
    return firebaseAuth;
};

/**
 * Returns true if the user's is a system administrator
 */
export const isSysAdmin = (user: ImmutableUser): boolean => {
    if (!user) {
        return false;
    }
    const userRoles = user.get('roles');
    if (!userRoles) {
        return false;
    }
    return user.get('roles').includes(ROLES.ADMIN);
};

/**
 * Returns true if the user's is a Solvay employee (sys admin or SAM)
 */
export const isSolvayUser = (user: ImmutableUser): boolean => {
    if (!user) {
        return false;
    }
    const userRoles = user.get('roles');
    if (!userRoles) {
        return false;
    }
    return Boolean(userRoles.includes(ROLES.ADMIN) || userRoles.includes(ROLES.SAM));
};

/**
 * If the user admin or has at least one client, then they are a solvextract user.
 */
export const isSolvExtractUser = (user: ImmutableUser): boolean =>
    Boolean(user && (isSysAdmin(user) || user.get('isSolvExtractUser')));

/**
 * Returns true if the user's is a Solvay client (PM)
 */
export const isClientUser = (user: ImmutableUser): boolean => {
    if (!user) {
        return false;
    }

    const userRoles = user.get('roles');
    if (!userRoles) {
        return false;
    }
    return Boolean(userRoles.includes(ROLES.PM) || userRoles.includes(ROLES.SPM));
};

/**
 * Returns true if the user's is a Solvay client (PM)
 */
export const isSuperPMUser = (user: ImmutableUser): boolean => {
    if (!user) {
        return false;
    }

    const userRoles = user.get('roles');
    if (!userRoles) {
        return false;
    }
    return Boolean(userRoles.includes(ROLES.SPM));
};

/**
 * Returns true if the user has a role (PM, SAM or Admin)
 * @param {ImmutableUser} user
 */
export const isAuthorized = (user: ImmutableUser) => {
    if (!user) {
        return false;
    }
    const roles = user.get('roles');
    return Boolean(
        roles &&
            roles.size > 0 &&
            roles.every((role: RoleConstant) => Object.keys(ROLES).includes(role))
    );
};

/**
 * Is the user allowed to be on the current route?
 */
export const isUserAuthorizedOnRoute = (user: ImmutableUser, route: RouteType) => {
    const routeRoles = route.userRoles;
    if (!user) {
        return Boolean(route.canBeUnauthorized);
    }
    const userRoles = user.get('roles');
    if (!userRoles) {
        return Boolean(route.canBeUnauthorized);
    }
    // a route may have no role (ex: login), if that is the case, then all roles are allowed
    if (routeRoles.length === 0) {
        return true;
    }
    return Boolean(userRoles.find((role: RoleConstant) => routeRoles.includes(role)));
};

/**
 * Gets the user's uiLanguage from their settings, fallback to DEFAULT_LOCALE
 */
export const getUsersLanguage = (user?: ImmutableUser): LanguageCodeConstant =>
    user.getIn(['preferences', 'global', 'language'], DEFAULT_LOCALE);

export const getLoginUserName = (user: ImmutableUser): string => {
    if (!user) {
        return '';
    }
    const userName = user.get('displayName');
    return userName || user.get('email');
};

/**
 * Grants permission to view the cyanex application
 */
export const canAccessCyanex = (user: ImmutableUser): boolean => {
    return isSolvayUser(user);
};
