// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, ButtonWrapper } from './styles';

// Components
import { PrimaryButton, SecondaryButton, SubtleLink } from 'components/_ReactUI_V1';

import type { IntlType } from 'types';
import { NAVIGATION_ROUTES } from '../../utils/constants';

import type { ImmutableUser } from 'services/Authentication/types';
import { isSolvayUser, isSysAdmin } from 'utils/authentication';

// Constants:

type Props = {
    user: ImmutableUser,
    circuitId: number;

    intl: IntlType,
    saveAsDisabled: boolean,
    saveDisabled: boolean,
    elevateDisabled: boolean,

    onSaveClicked: () => void,
    onSaveAsClicked: () => void,
    onElevateClicked: () => void,
};

class CircuitSetupFooter extends React.PureComponent<Props, null> {
    canUseV2 = () => {
        return isSolvayUser(this.props.user);
    };

    canElevate = () => {
        return isSysAdmin(this.props.user);
    };

    render() {
        return (
            <Wrapper>
                <ButtonWrapper>
                    {this.canUseV2() && (
                            <SubtleLink to={`${NAVIGATION_ROUTES.MINCHEM_V2}${NAVIGATION_ROUTES.CIRCUIT}${this.props.circuitId}/setup`}>
                                <SecondaryButton
                                    text={this.props.intl.formatMessage({
                                        id: 'components.CircuitSetupFooter.editInMinChemV2Button',
                                    })}
                                />
                            </SubtleLink>
                    )}
                    {this.canElevate() && (
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id:
                                    'components.CircuitSetupFooter.elevateToSolvExtractCircuitButton',
                            })}
                            disabled={this.props.elevateDisabled}
                            onClick={this.props.onElevateClicked}
                        />
                    )}
                    <SecondaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.CircuitSetupFooter.saveAsNewMinChemCircuitButton',
                        })}
                        disabled={this.props.saveAsDisabled}
                        onClick={this.props.onSaveAsClicked}
                    />
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.CircuitSetupFooter.saveButton',
                        })}
                        disabled={this.props.saveDisabled}
                        onClick={this.props.onSaveClicked}
                    />
                </ButtonWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(CircuitSetupFooter);
