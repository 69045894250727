// @flow strict

export const HELP_LINK = 'https://solvay.az1.qualtrics.com/jfe/form/SV_0q8QQpxVgjThLVP';

export const APPLICATION_NAME = 'SolvExtract';

export const APPLICATION_TYPES = {
    CYANEX: 'CYANEX',
    SOLVEXTRACT: 'SOLVEXTRACT',
    MINCHEM: 'MINCHEM',
};

export const REFRESH_FIREBASE_TOKEN_INTERVAL_VALUE = 600000; // Refresh firebase token every 10 minutes
export const REFRESH_FIREBASE_TOKEN_INTERVAL_LIMIT = 6; // Limit refresh interval count to 6
export const PHONE_VERIFICATION_RESEND_MINS_ALLOWED = 2; // Resend sms link allowed time (in mins)

export const ROLES = {
    PM: 'PM',
    SPM: 'SPM',
    SAM: 'SAM',
    ADMIN: 'ADMIN',
};

export const USER_DISCLAIMER_TYPES = {
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
};

export const DISCLAIMER_TYPES = {
    ...USER_DISCLAIMER_TYPES,
    EVALUATION_AGREEMENT: 'EVALUATION_AGREEMENT',
};

export const ROUTE_NAMES = {
    HOME: 'HOME',
    LOGIN: 'LOGIN',
    SETTINGS: 'SETTINGS',
    NOT_FOUND: 'NOT_FOUND',
    SYSTEM_STATUS: 'SYSTEM_STATUS',
    CYANEX: 'CYANEX',

    // MinChem:
    CIRCUIT_SETUP: 'CIRCUIT_SETUP',
    DATASET_COMPUTATION: 'DATASET_COMPUTATION',
    ISOTHERMS: 'ISOTHERMS',
    ISOTHERMS_PM: 'ISOTHERMS_PM',
    CIRCUIT_ELEVATION: 'CIRCUIT_ELEVATION',

    // SolvExtract:
    CIRCUIT_TRENDS: 'CIRCUIT_TRENDS',
    CIRCUIT_ARCHIVE: 'CIRCUIT_ARCHIVE',
    PLANT_CONFIGURATION: 'PLANT_CONFIGURATION',
    PLANT_TRENDS: 'PLANT_TRENDS',
    PLANT_DASHBOARD: 'PLANT_DASHBOARD',
    PLANT_ARCHIVE: 'PLANT_ARCHIVE',
    PLANT_CREATION_V1: 'PLANT_CREATION_V1',
    PLANT_CREATION_V2: 'PLANT_CREATION_V2',

    // RGS
    PLANT_REPORTS: 'PLANT_REPORTS',
    PLANT_REPORT: 'PLANT_REPORT',

    // Management
    CYANEX_REAGENTS: 'CYANEX_REAGENTS',
    MDR: 'MDR',
    REAGENTS: 'REAGENTS',
    USERS_CLIENTS_PLANTS_SAM: 'USERS_CLIENTS_PLANTS_SAM',
    USERS_CLIENTS_PLANTS_ADMIN: 'USERS_CLIENTS_PLANTS_ADMIN',

    USERS_ADMIN: 'USERS_ADMIN',
    USERS_SAM: 'USERS_SAM',
    USERS_PM: 'USERS_PM',

    PLANTS: 'PLANTS',
    CLIENTS: 'CLIENTS',
    DISCLAIMERS: 'DISCLAIMERS',
    MINCHEM_V2: 'MINCHEM_V2',
};

export const USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES = {
    USERS_PM: '/management/users/pm',
    USERS_SAM: '/management/users/sam',
    USERS_ADMIN: '/management/users/admin',
    CLIENTS: '/management/clients',
    PLANTS: '/management/plants',
};

export const NAVIGATION_ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    SETTINGS: '/settings',
    SYSTEM_STATUS: '/system-status',

    // Logged in:
    PLANT: '/plant/', // route prefix, appends id
    CIRCUIT: '/circuit/', // route prefix, appends id
    COMPUTATION: '/compute/', // route prefix, appends id
    COMPUTATION_DATASET: '/dataset/', // route postfix and prefix, appends id.
    ISOTHERMS: '/isotherms', // route prefix, appends id, potentially.
    ISOTHERMS_PM: '/isotherms', // route prefix, appends id, potentially.

    TRENDS: '/trends', // route postfix,
    ARCHIVE: '/archive', // route postfix.
    ELEVATE: '/elevate', // route postfix.

    MINCHEM_DASHBOARD: '/minchem/circuits',
    SOLVEXTRACT_DASHBOARD: '/solvextract/circuits',
    CYANEX: '/minchem_plus',

    // RGS:
    PLANT_REPORTS: '/reports', // route postfix to `/plant/{id}/`
    PLANT_REPORT: '/report/', // route postfix to `/plant/{id}/`, appends report id

    // Management:
    CYANEX_REAGENTS: '/management/reagents/minchem_plus',
    MDR: '/management/mdr',
    REAGENTS: '/management/reagents',
    USERS_CLIENTS_PLANTS_SAM: {
        USERS_PM: USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_PM,
    },
    USERS_CLIENTS_PLANTS_ADMIN: {
        ...USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES,
    },
    DISCLAIMERS: '/management/disclaimers',
    PLANT_CREATION_V2: '/management/plants/new',
    MINCHEM_V2: '/minchem',
};

export const ROUTES = {
    LOGIN: {
        hasTitle: false,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.LOGIN}`,
        match: `${NAVIGATION_ROUTES.LOGIN}`,
        name: ROUTE_NAMES.LOGIN,
        isPageResponsive: true,
        canBeUnauthorized: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    NOT_FOUND: {
        hasTitle: true,
        hasPageNavigator: false,
        path: '/404',
        match: '^/404$',
        name: ROUTE_NAMES.NOT_FOUND,
        isPageResponsive: true,
        canBeUnauthorized: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    // Logged in:
    HOME: {
        hasTitle: true,
        hasPageNavigator: false,
        path: '/',
        match: '^/$',
        name: ROUTE_NAMES.HOME,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    MINCHEM_DASHBOARD: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.MINCHEM_DASHBOARD}`,
        match: `${NAVIGATION_ROUTES.MINCHEM_DASHBOARD}`,
        name: ROUTE_NAMES.HOME,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    SOLVEXTRACT_DASHBOARD: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.SOLVEXTRACT_DASHBOARD}`,
        match: `${NAVIGATION_ROUTES.SOLVEXTRACT_DASHBOARD}`,
        name: ROUTE_NAMES.HOME,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    CYANEX: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.CYANEX}`,
        match: `${NAVIGATION_ROUTES.CYANEX}`,
        name: ROUTE_NAMES.CYANEX,
        isPageResponsive: true,
        userRoles: [ROLES.ADMIN, ROLES.SAM],
        refreshUserTokenOnInterval: true,
    },
    SETTINGS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.SETTINGS}`,
        match: `^${NAVIGATION_ROUTES.SETTINGS}$`,
        name: ROUTE_NAMES.SETTINGS,
        isPageResponsive: false, // TODO: MS-509
        userRoles: [],
        refreshUserTokenOnInterval: true,
    },
    SYSTEM_STATUS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.SYSTEM_STATUS}`,
        match: `^${NAVIGATION_ROUTES.SYSTEM_STATUS}$`,
        name: ROUTE_NAMES.SYSTEM_STATUS,
        isPageResponsive: false, // TODO
        userRoles: [ROLES.ADMIN, ROLES.SAM],
        refreshUserTokenOnInterval: false,
    },
    // MinChem Routes
    DATASET_COMPUTATION: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.COMPUTATION}:circuitId${
            NAVIGATION_ROUTES.COMPUTATION_DATASET
        }:datasetId`,
        match: `${NAVIGATION_ROUTES.COMPUTATION}([0-9]+)${
            NAVIGATION_ROUTES.COMPUTATION_DATASET
        }([0-9]+|new)`,
        name: ROUTE_NAMES.DATASET_COMPUTATION,
        isPageResponsive: false,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    ISOTHERMS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.ISOTHERMS}/:isothermId?`,
        match: `${NAVIGATION_ROUTES.ISOTHERMS}(/[0-9+]|create|predict)?`,
        name: ROUTE_NAMES.ISOTHERMS,
        isPageResponsive: false,
        userRoles: [ROLES.SAM, ROLES.ADMIN],
        refreshUserTokenOnInterval: false,
    },
    ISOTHERMS_PM: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.ISOTHERMS}/:isothermId?`,
        match: `${NAVIGATION_ROUTES.ISOTHERMS}(/[0-9+]|create|predict)?`,
        name: ROUTE_NAMES.ISOTHERMS_PM,
        isPageResponsive: false,
        userRoles: [ROLES.PM, ROLES.SPM],
        refreshUserTokenOnInterval: false,
    },
    // aka PLANT_TRENDS V2
    // Plant dashboard should always be on top of circuit setup otherwise it will be matched as circuit setup.
    PLANT_DASHBOARD: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.PLANT}:plantId/circuit/:circuitId`,
        match: `${NAVIGATION_ROUTES.PLANT}([0-9]+)/circuit/([0-9]+)`,
        name: ROUTE_NAMES.PLANT_DASHBOARD,
        isPageResponsive: false,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    CIRCUIT_SETUP: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.CIRCUIT}:circuitId`,
        match: `${NAVIGATION_ROUTES.CIRCUIT}([0-9]+)$`,
        name: ROUTE_NAMES.CIRCUIT_SETUP,
        isPageResponsive: false,
        userRoles: [],
        refreshUserTokenOnInterval: true,
    },
    CIRCUIT_ELEVATION: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.CIRCUIT}:circuitId${NAVIGATION_ROUTES.ELEVATE}`,
        match: `${NAVIGATION_ROUTES.CIRCUIT}([0-9]+)${NAVIGATION_ROUTES.ELEVATE}`,
        name: ROUTE_NAMES.CIRCUIT_ELEVATION,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },

    // SolvExtract Routes:
    CIRCUIT_TRENDS: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.CIRCUIT}:circuitId${NAVIGATION_ROUTES.TRENDS}`,
        match: `${NAVIGATION_ROUTES.CIRCUIT}([0-9]+)${NAVIGATION_ROUTES.TRENDS}`,
        name: ROUTE_NAMES.CIRCUIT_TRENDS,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    CIRCUIT_ARCHIVE: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.CIRCUIT}:circuitId${NAVIGATION_ROUTES.ARCHIVE}`,
        match: `${NAVIGATION_ROUTES.CIRCUIT}([0-9]+)${NAVIGATION_ROUTES.ARCHIVE}`,
        name: ROUTE_NAMES.CIRCUIT_ARCHIVE,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    PLANT_TRENDS: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.PLANT}:plantId${NAVIGATION_ROUTES.TRENDS}`,
        match: `${NAVIGATION_ROUTES.PLANT}([0-9]+)${NAVIGATION_ROUTES.TRENDS}`,
        name: ROUTE_NAMES.PLANT_TRENDS,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    PLANT_ARCHIVE: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.PLANT}:plantId${NAVIGATION_ROUTES.ARCHIVE}`,
        match: `${NAVIGATION_ROUTES.PLANT}([0-9]+)${NAVIGATION_ROUTES.ARCHIVE}`,
        name: ROUTE_NAMES.PLANT_ARCHIVE,
        isPageResponsive: true,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    // RGS routes:
    PLANT_REPORT: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.PLANT}:plantId${NAVIGATION_ROUTES.PLANT_REPORT}:reportId`,
        match: `${NAVIGATION_ROUTES.PLANT}([0-9]+)${NAVIGATION_ROUTES.PLANT_REPORT}([0-9+])`,
        name: ROUTE_NAMES.PLANT_REPORT,
        isPageResponsive: false,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },
    PLANT_REPORTS: {
        hasTitle: false,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.PLANT}:plantId${NAVIGATION_ROUTES.PLANT_REPORTS}`,
        match: `${NAVIGATION_ROUTES.PLANT}([0-9]+)${NAVIGATION_ROUTES.PLANT_REPORTS}`,
        name: ROUTE_NAMES.PLANT_REPORTS,
        isPageResponsive: false,
        userRoles: [],
        refreshUserTokenOnInterval: false,
    },

    // MANAGEMENT:
    CYANEX_REAGENTS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.CYANEX_REAGENTS}`,
        match: `${NAVIGATION_ROUTES.CYANEX_REAGENTS}`,
        name: ROUTE_NAMES.CYANEX_REAGENTS,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    MDR: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.MDR}`,
        match: `${NAVIGATION_ROUTES.MDR}`,
        name: ROUTE_NAMES.MDR,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    REAGENTS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.REAGENTS}`,
        match: `${NAVIGATION_ROUTES.REAGENTS}`,
        name: ROUTE_NAMES.REAGENTS,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: false,
    },
    CLIENTS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.CLIENTS}`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.CLIENTS}`,
        name: ROUTE_NAMES.CLIENTS,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: false,
    },
    PLANT_CREATION_V2: {
        // Must be before PLANTS
        hasTitle: true,
        hasPageNavigator: true,
        path: `${NAVIGATION_ROUTES.PLANT_CREATION_V2}`,
        match: `${NAVIGATION_ROUTES.PLANT_CREATION_V2}`,
        name: ROUTE_NAMES.PLANT_CREATION_V2,
        isPageResponsive: true,
        userRoles: [ROLES.ADMIN], // actions in MFE are validated by MS backend.
        refreshUserTokenOnInterval: false, // MFE does this
    },
    PLANT_CONFIGURATION: {
        // Must be before PLANTS
        hasTitle: true,
        hasPageNavigator: true,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}/:plantId`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}/([0-9]+)/*`,
        name: ROUTE_NAMES.PLANT_CONFIGURATION,
        isPageResponsive: true,
        userRoles: [ROLES.SAM, ROLES.ADMIN], // actions in MFE are validated by MS backend.
        refreshUserTokenOnInterval: false, // MFE does this
    },
    PLANTS: {
        // Must be after PLANT_CONFIGURATION
        hasTitle: true,
        hasPageNavigator: false,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.PLANTS}`,
        name: ROUTE_NAMES.PLANTS,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    USERS_ADMIN: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_ADMIN}`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_ADMIN}`,
        name: ROUTE_NAMES.USERS_ADMIN,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    USERS_SAM: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_SAM}`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_SAM}`,
        name: ROUTE_NAMES.USERS_SAM,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    USERS_PM: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_PM}`,
        match: `${USERS_CLIENTS_PLANTS_NAVIGATION_ROUTES.USERS_PM}`,
        name: ROUTE_NAMES.USERS_PM,
        isPageResponsive: false,
        userRoles: [ROLES.SAM, ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    DISCLAIMERS: {
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.DISCLAIMERS}`,
        match: `${NAVIGATION_ROUTES.DISCLAIMERS}`,
        name: ROUTE_NAMES.DISCLAIMERS,
        isPageResponsive: false,
        userRoles: [ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
    MINCHEM_V2: {
        // Must be after PLANT_CONFIGURATION
        hasTitle: true,
        hasPageNavigator: false,
        path: `${NAVIGATION_ROUTES.MINCHEM_V2}`,
        match: `${NAVIGATION_ROUTES.MINCHEM_V2}`,
        name: ROUTE_NAMES.MINCHEM_V2,
        isPageResponsive: false,
        userRoles: [ROLES.SAM, ROLES.ADMIN],
        refreshUserTokenOnInterval: true,
    },
};

// Careful!: not all route names have a route.
export const PM_NAVIGATION_ROUTES = [ROUTE_NAMES.HOME, ROUTE_NAMES.ISOTHERMS_PM];

export const SAM_NAVIGATION_ROUTES = [
    ROUTE_NAMES.HOME,
    ROUTE_NAMES.ISOTHERMS,
    ROUTE_NAMES.USERS_CLIENTS_PLANTS_SAM,
];

export const ADMIN_NAVIGATION_ROUTES = [
    ROUTE_NAMES.HOME,
    ROUTE_NAMES.ISOTHERMS,
    ROUTE_NAMES.USERS_CLIENTS_PLANTS_ADMIN,
    ROUTE_NAMES.REAGENTS,
    ROUTE_NAMES.DISCLAIMERS,
    ROUTE_NAMES.CYANEX,
];

export const MINCHEM_PAGES_WITH_NAVIGATION = [
    ROUTE_NAMES.CIRCUIT_SETUP,
    ROUTE_NAMES.DATASET_COMPUTATION,
];

export const SOLVEXTRACT_PAGES_WITH_NAVIGATION = [
    ROUTE_NAMES.PLANT_REPORTS, // RGS
    // SolvExtract Dashboards
    ROUTE_NAMES.CIRCUIT_TRENDS,
    ROUTE_NAMES.PLANT_TRENDS,
    ROUTE_NAMES.PLANT_DASHBOARD,
    // SolvExtract Archives
    ROUTE_NAMES.CIRCUIT_ARCHIVE,
    ROUTE_NAMES.PLANT_ARCHIVE,
];

export const NEW_DATASET_POSTFIX = 'new';

export const BLANK_TEMPLATE = 'BLANK_TEMPLATE';

export const SIDEBAR_STATES = {
    FULL: 'FULL',
    TAB: 'TAB',
};

export const CIRCUIT_COMPUTE_SIDEBAR_ITEMS = {
    MIMIC: 'MIMIC',
    MCCABE: 'MCCABE',
    TWO_D: '2D',
    THREE_D: '3D',
};

// Language
export const LANGUAGE_OPTIONS = {
    EN: 'EN',
    ES: 'ES',
    ZH: 'ZH',
};

export const TOAST_DURATION = 5000; // in ms.

/* Styles */
// Modal
export const MODAL_WIDTH = {
    SMALL: '358px',
    BIG: '440px',
    LARGE: '558px',
    XLARGE: '1064px',
};

export const STYLE_VALUES = {
    MOBILE: {
        APP_WRAPPER_MIN_WIDTH: '320px',
    },
    SCREEN: {
        MAX_WIDTH: '1200px',
        APP_WRAPPER_MIN_WIDTH: '1280px',
    },
    XLARGE: {
        MAX_WIDTH: '1440px',
        APP_WRAPPER_MIN_WIDTH: '1440px',
    },
    SIDEBAR: {
        WIDTH: '360px',
    },
    HEADER: {
        HEIGHT: '61px',
        HEIGHT_RAW: 61,
        RESPONSIVE_AT: '800px', // Remove "sidebar" at
    },
    FOOTER: {
        MIN_HEIGHT: '47px',
    },
    GUTTERS: {
        NORMAL: '24px',
        LARGE: '48px',
    },
    INPUT_SELECT_MAX_MENU_HEIGHTS: {
        LARGE: '140px',
        MEDIUM: '102px',
        SMALL: '64px',
    },
    RESPONSIVE_AT: '800px',
    RESPONSIVE_AT_LARGE: '1100px', // TODO: Move into route config?
    MAJOR_KPI_STATUS_HEADER: {
        HEIGHT: '35px',
    },
    RESPONSIVE_SIDEBAR: {
        COLLAPSED_HEIGHT: '58px',
        COLLAPSED_HEIGHT_RAW: 58,
    },
    DEFAULT_INPUT_NUMBER_STYLES: {
        height: '30px',
        width: '100%',
        margin: '0',
        padding: '0 5px',
        fontSize: '12px',
        textAlign: 'center',
    },
    DEFAULT_SMALL_INPUT_NUMBER_STYLES: {
        height: '30px',
        width: '80px',
        fontSize: '12px',
    },
};

// Isotherm
export const DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR = 1;
export const CREATE_ISOTHERM_DATAPOINTS_COUNT = 16;

export const ISOTHERM_MAX_POLYNOMIAL_ORDER = 4;

export const MAX_ISOTHERM_SELECT_COUNT = 5;

export const CASCADE_RF_OPTIONS = [0.05, 0.1, 0.2, 0.3, 0.4, 0.5];
export const RETURN_RF_OPTIONS = [0.1, 0.2, 0.5, 1.0, 1.5];
export const DEFAULT_CASCADE_RELAXATION_FACTOR = CASCADE_RF_OPTIONS[0];
export const DEFAULT_RETURN_RELAXATION_FACTOR = RETURN_RF_OPTIONS[0];

export const ISOTHERM_BASE_CONSTANT = {
    EXTRACT: 'EXTRACT',
    STRIP: 'STRIP',
};

export const ISOTHERM_POLYNOMIAL_TYPE = {
    MINCHEM: 'MINCHEM',
    RATIONAL: 'RATIONAL',
    SIMPLE: 'SIMPLE',
    ARITHMETIC_INTERPOLATION: 'ARITHMETIC_INTERPOLATION',
    LOGARITHMIC_INTERPOLATION: 'LOGARITHMIC_INTERPOLATION',
};

export const ISOTHERM_SIDEBAR_TYPES = {
    CREATE: 'CREATE',
    PREDICT: 'PREDICT',
    UPDATE_CREATE: 'UPDATE_CREATE',
    UPDATE_PREDICT: 'UPDATE_PREDICT',
};

export const ISOTHERM_DATAPOINTS_TYPE = {
    AQUEOUS_CONCENTRATION: 'AQUEOUS_CONCENTRATION',
    ORGANIC_CONCENTRATION: 'ORGANIC_CONCENTRATION',
};

// Circuit
export const UNIT_TYPES = {
    US: 'US',
    METRIC: 'METRIC',
    LITRE: 'LITRE',
};

export const PRODUCTION_UNIT_TYPES = {
    TONS_DAILY: 'TONS_DAILY',
    '1000_LBS_DAILY': '1000_LBS_DAILY',
};

// Circuit Stages
export const STAGE_TYPES = {
    EXTRACT: 'EXTRACT',
    STRIP: 'STRIP',
    ORGANIC_TANK: 'ORGANIC_TANK',
    WASHER: 'WASHER',
};

export const STAGE_TYPES_WITH_ISOTHERMS = [STAGE_TYPES.EXTRACT, STAGE_TYPES.STRIP];

export const WASHER_TYPES = {
    ETS: 'ETS',
    STE: 'STE',
};

// TODO: Replace for KPI_TYPES
export const STAGE_VALUE_TYPES = {
    OA_RATIO: 'OA_RATIO',
    STAGE_EFFICIENCY: 'STAGE_EFFICIENCY',
    ISOTHERM: 'ISOTHERM',
    ORGANIC_DEPTH: 'ORGANIC_DEPTH',

    PRIMARY_MIXER_SPEED: 'PRIMARY_MIXER_SPEED',
    PRIMARY_MIXER_OA_RATIO: 'PRIMARY_MIXER_OA_RATIO',
    PRIMARY_MIXER_RETENTION_TIME: 'PRIMARY_MIXER_RETENTION_TIME',

    SETTLER_ORGANIC_PHASE_VELOCITY: 'SETTLER_ORGANIC_PHASE_VELOCITY',
    SETTLER_AQUEOUS_PHASE_VELOCITY: 'SETTLER_AQUEOUS_PHASE_VELOCITY',
    SETTLER_ORGANIC_RETENTION_TIME: 'SETTLER_ORGANIC_RETENTION_TIME',
    SETTLER_AQUEOUS_RETENTION_TIME: 'SETTLER_AQUEOUS_RETENTION_TIME',
    SETTLER_SPECIFIC_FLOW: 'SETTLER_SPECIFIC_FLOW',

    CRUD_DEPTH: 'CRUD_DEPTH',
    TANK_CU_COMPOSITION: 'TANK_CU_COMPOSITION',
    TANK_FLOWRATE: 'TANK_FLOWRATE',
    TANK_LEVEL: 'TANK_LEVEL',
    PRE_WASH_COMPOSITION: 'PRE_WASH_COMPOSITION',
    POST_WASH_COMPOSITION: 'POST_WASH_COMPOSITION',
    WASH_OFFSET: 'WASH_OFFSET',
    WASH_WATER: 'WASH_WATER',
};

export const DEFAULT_STAGE_VALUE_PRECISIONS = {
    [STAGE_VALUE_TYPES.OA_RATIO]: 2,
    [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: 2,
    [STAGE_VALUE_TYPES.ORGANIC_DEPTH]: 2,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_SPEED]: 2,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_OA_RATIO]: 2,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_RETENTION_TIME]: 2,
    [STAGE_VALUE_TYPES.SETTLER_ORGANIC_PHASE_VELOCITY]: 2,
    [STAGE_VALUE_TYPES.SETTLER_AQUEOUS_PHASE_VELOCITY]: 2,
    [STAGE_VALUE_TYPES.SETTLER_ORGANIC_RETENTION_TIME]: 2,
    [STAGE_VALUE_TYPES.SETTLER_AQUEOUS_RETENTION_TIME]: 2,
    [STAGE_VALUE_TYPES.SETTLER_SPECIFIC_FLOW]: 2,
    [STAGE_VALUE_TYPES.CRUD_DEPTH]: 2,
    [STAGE_VALUE_TYPES.TANK_CU_COMPOSITION]: 2,
    [STAGE_VALUE_TYPES.TANK_FLOWRATE]: 2,
    [STAGE_VALUE_TYPES.TANK_LEVEL]: 2,
    [STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION]: 2,
    [STAGE_VALUE_TYPES.WASH_OFFSET]: 2,
    [STAGE_VALUE_TYPES.POST_WASH_COMPOSITION]: 2,
};

export const ISOTHERM_VALUE_MODES = {
    PREDICT: 'PREDICT',
    SELECT: 'SELECT',
};

export const MAX_STAGE_COUNT = 10;
export const MIN_STAGE_COUNT = 0;

// Circuit Stage Values
export const STAGE_VALUE_LIMITS = {
    [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: {
        MINIMUM: 0,
        MAXIMUM: 200,
    },
    [STAGE_VALUE_TYPES.OA_RATIO]: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
};

export const COMPUTE_UNITS = {
    GPL: 'GPL',
    DELTA_GPL: 'DELTA_GPL',
    M3H: 'M3H',
    PERCENT: 'PERCENT',
    DISTANCE: 'DISTANCE',
};

// Circuit Streams
export const STREAM_WATER_TYPES = {
    AQUEOUS: 'AQUEOUS',
    ORGANIC: 'ORGANIC',
};

export const STREAM_TYPES = {
    CONTINUE: 'CONTINUE',
    FEED: 'FEED',
    BLEED: 'BLEED',
    BLEND: 'BLEND',
    SKIP: 'SKIP',
    BYPASS_BLEND: 'BYPASS_BLEND',
    BYPASS_FEED: 'BYPASS_FEED',
    BYPASS_BLEED: 'BYPASS_BLEED',
    LEAN_FEED_BLEED: 'LEAN_FEED_BLEED',
};

export const STREAM_CIRCUITS = {
    AQUEOUS: 'AQUEOUS', // Technically this should be the PLS
    ORGANIC: 'ORGANIC',
    ELECTROLYTE: 'ELECTROLYTE', // Technically this is an Aqueous Water Flow Type
};

// If you add a metal add it to the stream values too.
export const METAL_TYPES = {
    MG: 'MG',
    AL: 'AL',
    FE: 'FE',
    MN: 'MN',
    CL: 'CL',
    CO: 'CO',
    NITRATE: 'NITRATE',
};

export const STREAM_VALUE_TYPES = {
    // METAL COMPOSITIONs:
    COMPOSITION: 'COMPOSITION',
    PRE_COMPOSITION: 'PRE_COMPOSITION',
    POST_COMPOSITION: 'POST_COMPOSITION',

    PLS: 'PLS',
    PRE_PLS_CU: 'PRE_PLS_CU',
    RAFFINATE: 'RAFFINATE',

    SPENT: 'SPENT',
    ADVANCE: 'ADVANCE',
    PHYSICAL_FE_TRANSFER: 'PHYSICAL_FE_TRANSFER',
    CHEMICAL_FE_TRANSFER: 'CHEMICAL_FE_TRANSFER',
    LEAN_FE_MN_RATIO: 'LEAN_FE_MN_RATIO',
    STRIPPED_FE_MN_RATIO: 'STRIPPED_FE_MN_RATIO',
    ESTIMATED_ORGANIC_FE: 'ESTIMATED_ORGANIC_FE',
    BARREN_ORGANIC_TANK_LEVEL: 'BARREN_ORGANIC_TANK_LEVEL',
    // View the self calling function: appendMetalsToStreamValueTypes

    // Flowrates:
    FLOWRATE: 'FLOWRATE',
    FLOWRATE_PERCENT: 'FLOWRATE_PERCENT',
    ACID_MAKEUP: 'ACID_MAKEUP',
    WATER_MAKEUP: 'WATER_MAKEUP',
    ACID_MAKEUP_TREND: 'ACID_MAKEUP_TREND',
    WATER_MAKEUP_TREND: 'WATER_MAKEUP_TREND',

    // Acids/pHs
    ACID: 'ACID',
    PH: 'PH',
    PRE_PH: 'PRE_PH',
    FIXED_PH: 'FIXED_PH',

    // Aggregates:
    RECOVERY_PERCENT: 'RECOVERY_PERCENT',
    LOADED_PERCENT: 'LOADED_PERCENT', // on a per circuit basis instead?
    NET_TRANSFER: 'NET_TRANSFER',
    STRIPPED_PERCENT: 'STRIPPED_PERCENT',
    AINO_ENTRAINMENT_AVERAGE: 'AINO_ENTRAINMENT_AVERAGE',
};
(function appendMetalsToStreamValueTypes() {
    Object.keys(METAL_TYPES).forEach((metal) => {
        const metalComposition = `${metal}_COMPOSITION`;
        // Compositions for wash only to be replaces with the
        // normal composition valuetype when washes have separate in and out streams
        const metalTransfer = `TOTAL_${metal}_TRANSFER`;
        const totalMetalTransferPercent = `TOTAL_${metal}_WASHED_PERCENT`;
        const ainoEntrainment = `AINO_ENTRAINMENT_${metal}`;
        const leanMetalBled = `LEAN_${metal}_BLED`;
        const requiredLeanMetalBleed = `REQUIRED_LEAN_${metal}_BLEED`;

        STREAM_VALUE_TYPES[metalComposition] = metalComposition;
        STREAM_VALUE_TYPES[metalTransfer] = metalTransfer;
        STREAM_VALUE_TYPES[ainoEntrainment] = ainoEntrainment;
        STREAM_VALUE_TYPES[totalMetalTransferPercent] = totalMetalTransferPercent;
        STREAM_VALUE_TYPES[leanMetalBled] = leanMetalBled;
        STREAM_VALUE_TYPES[requiredLeanMetalBleed] = requiredLeanMetalBleed;
    });
})();

export const DATASET_VALUE_TYPES = {
    // Reagent/oxime concentrations
    REAGENT_CONCENTRATION: 'REAGENT_CONCENTRATION',
    OXIME_RATIO: 'OXIME_RATIO',
    OXIME_GPL: 'OXIME_GPL',
    MAX_LOAD: 'MAX_LOAD',
    MAX_LOAD_OFFSET: 'MAX_LOAD_OFFSET',
    ALDOXIME_PERCENT: 'ALDOXIME_PERCENT',
    KETOXIME_PERCENT: 'KETOXIME_PERCENT',

    // Overall values:
    CU_TRANSFERRED: 'CU_TRANSFERRED',
    OVERALL_RECOVERY: 'OVERALL_RECOVERY',
    ELECTROLYTE_MASS_BALANCE: 'ELECTROLYTE_MASS_BALANCE',
    ORGANIC_MASS_BALANCE: 'ORGANIC_MASS_BALANCE',
    PLS_MASS_BALANCE: 'PLS_MASS_BALANCE',
    TSS: 'TSS',
};

export const PLANT_VALUE_TYPES = {
    OVERALL_RECOVERY: 'OVERALL_RECOVERY',
    OVERALL_CU_TRANSFER: 'OVERALL_CU_TRANSFER',
    REAGENT_TANK_LEVEL: 'REAGENT_TANK_LEVEL',
    REAGENT_TANK_LEVEL_2: 'REAGENT_TANK_LEVEL_2',
    DILUENT_TANK_LEVEL: 'DILUENT_TANK_LEVEL',
    DILUENT_TANK_LEVEL_2: 'DILUENT_TANK_LEVEL_2',
    REAGENT_ADDITION: 'REAGENT_ADDITION',
    DILUENT_ADDITION: 'DILUENT_ADDITION',
    TOTAL_PLS_FLOWRATE: 'TOTAL_PLS_FLOWRATE',
    TOTAL_ELECTROLYTE_FLOWRATE: 'TOTAL_ELECTROLYTE_FLOWRATE',
    TOTAL_ORGANIC_FLOWRATE: 'TOTAL_ORGANIC_FLOWRATE',
    TOTAL_RAFFINATE: 'TOTAL_RAFFINATE',
    TOTAL_WATER_MAKEUP: 'TOTAL_WATER_MAKEUP',
    TOTAL_ACID_MAKEUP: 'TOTAL_ACID_MAKEUP',
    LEAN_BLEED: 'LEAN_BLEED',
    LEAN_CL: 'LEAN_CL',
    LEAN_CO: 'LEAN_CO',
    LEAN_FE: 'LEAN_FE',
    LEAN_MN: 'LEAN_MN',
    LEAN_NITRATE: 'LEAN_NITRATE',
    COMMERCIAL_RICH_CU: 'COMMERCIAL_RICH_CU',
    CURRENT: 'CURRENT',
    CURRENT_EFFICIENCY: 'CURRENT_EFFICIENCY',
    CURRENT_DENSITY: 'CURRENT_DENSITY',
    CELLS_HARVESTED: 'CELLS_HARVESTED',
    CU_HARVESTED: 'CU_HARVESTED',
    CELL_ELECTROLYTE_TEMPERATURE: 'CELL_ELECTROLYTE_TEMPERATURE',
    TOTAL_WASH_WATER: 'TOTAL_WASH_WATER',
    TOTAL_REQUIRED_LEAN_MG_BLEED: 'TOTAL_REQUIRED_LEAN_MG_BLEED',
    TOTAL_REQUIRED_LEAN_AL_BLEED: 'TOTAL_REQUIRED_LEAN_AL_BLEED',
    TOTAL_REQUIRED_LEAN_FE_BLEED: 'TOTAL_REQUIRED_LEAN_FE_BLEED',
    TOTAL_REQUIRED_LEAN_MN_BLEED: 'TOTAL_REQUIRED_LEAN_MN_BLEED',
    TOTAL_REQUIRED_LEAN_CL_BLEED: 'TOTAL_REQUIRED_LEAN_CL_BLEED',
    TOTAL_REQUIRED_LEAN_CO_BLEED: 'TOTAL_REQUIRED_LEAN_CO_BLEED',
    TOTAL_REQUIRED_LEAN_NITRATE_BLEED: 'TOTAL_REQUIRED_LEAN_NITRATE_BLEED',
};

export const MIMIC_DIAGRAM_HEADER_VALUES_MEUM = [
    // NOTE: Reagent Concentration, oxime ratio, oxime GPL should NOT be in here.
    DATASET_VALUE_TYPES.CU_TRANSFERRED,
    DATASET_VALUE_TYPES.OVERALL_RECOVERY,
];

export const MIMIC_DIAGRAM_HEADER_VALUES_SOLVEXTRACT = [
    // NOTE: Reagent Concentration, oxime ratio, oxime GPL should NOT be in here.
    ...MIMIC_DIAGRAM_HEADER_VALUES_MEUM,
    DATASET_VALUE_TYPES.MAX_LOAD,
    // DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE,
    // DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,
    // DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
];

export const DATASET_VALUE_LIMITS = {
    REAGENT_CONCENTRATION: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
    OXIME_GPL: {
        MINIMUM: 0,
        MAXIMUM: null,
    },
    OXIME_RATIO: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
    CU_TRANSFERRED: {
        MINIMUM: 0,
        MAXIMUM: null,
    },
    OVERALL_RECOVERY: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
};

/**
 * Stream values order defines which stream values should appear on top of the stream, in what order.
 * Streams without values, or a null value will be filtered out
 */
export const STREAM_VALUE_ORDER = {
    [STREAM_VALUE_TYPES.SPENT]: 8,
    [STREAM_VALUE_TYPES.SPENT]: 8,
    [STREAM_VALUE_TYPES.ACID]: 7,
    [STREAM_VALUE_TYPES.ACID_MAKEUP]: null,
    [STREAM_VALUE_TYPES.WATER_MAKEUP]: null,
    [STREAM_VALUE_TYPES.ACID_MAKEUP_TREND]: null,
    [STREAM_VALUE_TYPES.WATER_MAKEUP_TREND]: null,
    [STREAM_VALUE_TYPES.PRE_PLS_CU]: null,
    [STREAM_VALUE_TYPES.PRE_PH]: null,
    [STREAM_VALUE_TYPES.PLS]: 8,
    [STREAM_VALUE_TYPES.PH]: 7,
    [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: 8,
    [STREAM_VALUE_TYPES.ADVANCE]: 0,
    [STREAM_VALUE_TYPES.RAFFINATE]: 7,
    [STREAM_VALUE_TYPES.COMPOSITION]: 5,
    [STREAM_VALUE_TYPES.FLOWRATE]: 6,
    [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: 6,
    [STREAM_VALUE_TYPES.NET_TRANSFER]: 7,
    [STREAM_VALUE_TYPES.LOADED_PERCENT]: 0,
    [STREAM_VALUE_TYPES.POST_COMPOSITION]: 1,
    [STREAM_VALUE_TYPES.PRE_COMPOSITION]: {
        'EXTRACT-TO-STRIP': 0, // left side organic continue stream
        'STRIP-TO-EXTRACT': 7, // right side organic continue stream
    },
    [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: 0, // should always be last.
};

export const STAGE_VALUE_ORDER = {
    // cannot contain zeros.
    [STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION]: 1,
    [STAGE_VALUE_TYPES.WASH_OFFSET]: 2,
    [STAGE_VALUE_TYPES.POST_WASH_COMPOSITION]: 3,
};

/**
 * Stream values order defines which stream values should appear on top of the stream, in what order.
 */
export const DATASET_VALUE_ORDER = {
    [DATASET_VALUE_TYPES.REAGENT_CONCENTRATION]: 0,
    [DATASET_VALUE_TYPES.OVERALL_RECOVERY]: 1,
    [DATASET_VALUE_TYPES.CU_TRANSFERRED]: 2,
    [DATASET_VALUE_TYPES.OXIME_RATIO]: 3,
    [DATASET_VALUE_TYPES.OXIME_GPL]: 4,
};

export const VALUE_STATUS = {
    COMPUTE: 'COMPUTE',
    COMPUTE_USING: 'COMPUTE_USING',
    COMPUTE_USING_OPTIONAL: 'COMPUTE_USING_OPTIONAL',
    HIDDEN: 'HIDDEN',
};

export const STREAM_VALUE_LIMITS = {
    [STREAM_VALUE_TYPES.FLOWRATE]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.COMPOSITION]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.SPENT]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.ACID]: {
        MINIMUM: 0.01,
        MAXIMUM: 500,
    },
    [STREAM_VALUE_TYPES.ADVANCE]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.PLS]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.PH]: {
        MINIMUM: 0.01,
        MAXIMUM: 15,
    },
    [STREAM_VALUE_TYPES.RAFFINATE]: {
        MINIMUM: 0.0001,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
    [STREAM_VALUE_TYPES.LOADED_PERCENT]: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
    [STREAM_VALUE_TYPES.NET_TRANSFER]: {
        MINIMUM: 0,
        MAXIMUM: 10,
    },
    [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
    [STREAM_VALUE_TYPES.POST_COMPOSITION]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    // TODO: validate allowed minimum and maximum for pre bypass composition
    [STREAM_VALUE_TYPES.PRE_COMPOSITION]: {
        MINIMUM: 0.01,
        MAXIMUM: null,
    },
    [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: {
        MINIMUM: 0,
        MAXIMUM: 100,
    },
};

// These Value Types use the circuit's production units instead of their units.
export const VALUE_TYPES_WITH_PRODUCTION_UNITS = [
    DATASET_VALUE_TYPES.CU_TRANSFERRED,
    PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER,
    DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE,
    DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,
    DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
];

// Circuit Diagram Compute Values Structure
export const DEFAULT_STREAM_VALUE_STATUSES = {
    [STREAM_TYPES.CONTINUE]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
            [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.POST_COMPOSITION]: VALUE_STATUS.HIDDEN,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'EXTRACT-TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.POST_COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            'STRIP-TO-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
            },
            'EXTRACT-TO-STRIP': {
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            'STRIP-TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.EXTRACT}-TO-${STAGE_TYPES.ORGANIC_TANK}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            [`${STAGE_TYPES.ORGANIC_TANK}-TO-${STAGE_TYPES.STRIP}`]: {
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.EXTRACT}-TO-${STAGE_TYPES.WASHER}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.STRIP}`]: {
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.EXTRACT}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.STRIP}-TO-${STAGE_TYPES.WASHER}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.ORGANIC_TANK}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
        },
    },
    [STREAM_TYPES.FEED]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            [STREAM_VALUE_TYPES.PLS]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.PH]: VALUE_STATUS.COMPUTE_USING,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            },
            'TO-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.SPENT]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.ACID]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STREAM_TYPES.BLEND]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.PLS]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.PH]: VALUE_STATUS.COMPUTE_USING, // all isotherms will be predicted by default when there is a blend hence all blends will have a PH value enabled to be entered
        },
    },
    [STREAM_TYPES.BLEED]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: VALUE_STATUS.COMPUTE, // will be overwritten in helper function
            [STREAM_VALUE_TYPES.RAFFINATE]: VALUE_STATUS.COMPUTE,
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'FROM-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            'FROM-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.ADVANCE]: VALUE_STATUS.COMPUTE, // will be overwritten in helper function
        },
    },
    [STREAM_TYPES.SKIP]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
            [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.POST_COMPOSITION]: VALUE_STATUS.HIDDEN,
        },
    },
    [STREAM_TYPES.BYPASS_FEED]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STREAM_TYPES.BYPASS_BLEND]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STREAM_TYPES.BYPASS_BLEED]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE,
        },
    },
};

/**
 * All stream value statuses when circuit diagram is in Analysis Mode.
 */
export const ANALYSIS_MODE_STREAM_VALUE_STATUSES = {
    [STREAM_TYPES.CONTINUE]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING_OPTIONAL,
            [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.POST_COMPOSITION]: VALUE_STATUS.HIDDEN,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'EXTRACT-TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING_OPTIONAL,
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.POST_COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            'STRIP-TO-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING_OPTIONAL,
            },
            'EXTRACT-TO-STRIP': {
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            'STRIP-TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            [`${STAGE_TYPES.EXTRACT}-TO-${STAGE_TYPES.ORGANIC_TANK}`]: {
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            [`${STAGE_TYPES.ORGANIC_TANK}-TO-${STAGE_TYPES.STRIP}`]: {
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.EXTRACT}-TO-${STAGE_TYPES.WASHER}`]: {
                [STREAM_VALUE_TYPES.PRE_COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.STRIP}`]: {
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.EXTRACT}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN, // will be overwritten by helper function
            },
            [`${STAGE_TYPES.STRIP}-TO-${STAGE_TYPES.WASHER}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            [`${STAGE_TYPES.WASHER}-TO-${STAGE_TYPES.ORGANIC_TANK}`]: {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.HIDDEN,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING_OPTIONAL,
        },
    },
    [STREAM_TYPES.FEED]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.PLS]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.PH]: VALUE_STATUS.COMPUTE_USING,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'TO-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            },
            'TO-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.SPENT]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.ACID]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STREAM_TYPES.BLEED]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: VALUE_STATUS.COMPUTE,
            [STREAM_VALUE_TYPES.RAFFINATE]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
        },
        [STREAM_CIRCUITS.ORGANIC]: {
            'FROM-EXTRACT': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.LOADED_PERCENT]: VALUE_STATUS.COMPUTE,
            },
            'FROM-STRIP': {
                [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
                [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: VALUE_STATUS.COMPUTE,
                [STREAM_VALUE_TYPES.NET_TRANSFER]: VALUE_STATUS.COMPUTE,
            },
        },
        [STREAM_CIRCUITS.ELECTROLYTE]: {
            [STREAM_VALUE_TYPES.ADVANCE]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STREAM_TYPES.BLEND]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.FLOWRATE]: VALUE_STATUS.HIDDEN,
            [STREAM_VALUE_TYPES.PLS]: VALUE_STATUS.COMPUTE_USING,
            [STREAM_VALUE_TYPES.PH]: VALUE_STATUS.COMPUTE_USING, // all isotherms will be predicted by default when there is a blend hence all blends will have a PH value enabled to be entered
        },
    },
    [STREAM_TYPES.SKIP]: {
        [STREAM_CIRCUITS.AQUEOUS]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING_OPTIONAL,
        },
    },
    [STREAM_TYPES.BYPASS_FEED]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN,
        },
    },
    [STREAM_TYPES.BYPASS_BLEND]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: VALUE_STATUS.HIDDEN,
        },
    },
    [STREAM_TYPES.BYPASS_BLEED]: {
        [STREAM_CIRCUITS.ORGANIC]: {
            [STREAM_VALUE_TYPES.COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
        },
    },
};

export const DIAGRAM_DISPLAY_MODES = {
    SETUP: 'SETUP',
    COMPUTE: 'COMPUTE',
    COMPUTED: 'COMPUTED',
};

// Presets & Datasets

export const DATASET_MODES = {
    DESIGN_MODE: 'DESIGN_MODE',
    ANALYSIS_MODE: 'ANALYSIS_MODE',
};

/**
 *  Stream values according to compute design sidebar options. Rules reference can be found here:
 *  https://docs.google.com/spreadsheets/d/1ivdC4CNo8iM3t3wBvP_6aXQJxvCAVi0PWCTAwmr2Lh4/edit#gid=1426935309
 *  Note that this structure is only applicable for the dataset design mode only.
 */

/**
 * Sidebar type variables
 */
export const DESIGN_PRESET_TYPES = {
    [STAGE_TYPES.ORGANIC_TANK]: null,
    [STAGE_TYPES.WASHER]: null,
    [STAGE_TYPES.EXTRACT]: {
        [VALUE_STATUS.COMPUTE]: {
            RECOVERY_RAFFINATE: 'RECOVERY_RAFFINATE',
            OA_RATIO: 'OA_RATIO',
            REAGENT_CONCENTRATION: 'REAGENT_CONCENTRATION',
        },
        [VALUE_STATUS.COMPUTE_USING]: {
            OA_RATIO: 'OA_RATIO',
            FLOWRATES: 'FLOWRATES',
            RECOVERY_PERCENT: 'RECOVERY_PERCENT',
            RAFFINATE: 'RAFFINATE',
        },
    },
    [STAGE_TYPES.STRIP]: {
        [VALUE_STATUS.COMPUTE]: {
            ADVANCE: 'ADVANCE',
            OA_RATIO: 'OA_RATIO',
        },
        [VALUE_STATUS.COMPUTE_USING]: {
            OA_RATIO: 'OA_RATIO',
            FLOWRATES: 'FLOWRATES',
        },
    },
};

export const ANALYSIS_PRESET_TYPES = {
    STAGE_EFFICIENCY: 'STAGE_EFFICIENCY',
};

// Basic structure representing preset options
// Used in sidebar
export const DESIGN_PRESET_STRUCTURE = {
    [STAGE_TYPES.ORGANIC_TANK]: null,
    [STAGE_TYPES.EXTRACT]: {
        // compute RECOVERY_RAFFINATE using OA RATIO or FLOWRATES
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.RECOVERY_RAFFINATE]: [
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.OA_RATIO,
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.FLOWRATES,
        ],
        // compute OA RATIO using RECOVERY or FLOWRATES
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.OA_RATIO]: [
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.RAFFINATE,
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.RECOVERY_PERCENT,
        ],
        // compute REAGENT CONCENTRATION using RECOVERY or FLOWRATES
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.REAGENT_CONCENTRATION]: [
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.OA_RATIO,
            DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.FLOWRATES,
        ],
    },
    [STAGE_TYPES.STRIP]: {
        // compute ADVANCE using OA RATIO or FLOWRATES
        [DESIGN_PRESET_TYPES.STRIP.COMPUTE.ADVANCE]: [
            DESIGN_PRESET_TYPES.STRIP.COMPUTE_USING.OA_RATIO,
            DESIGN_PRESET_TYPES.STRIP.COMPUTE_USING.FLOWRATES,
        ],
        // compute OA RATIO using nothing, meaning compute without custom stream values
        [DESIGN_PRESET_TYPES.STRIP.COMPUTE.OA_RATIO]: [],
    },
};

export const PRESET_STAGE_VALUE_STATUSES = {
    [STAGE_TYPES.ORGANIC_TANK]: {
        [DATASET_MODES.DESIGN_MODE]: {
            [STAGE_VALUE_TYPES.TANK_CU_COMPOSITION]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.TANK_FLOWRATE]: VALUE_STATUS.COMPUTE,
        },
        [DATASET_MODES.ANALYSIS_MODE]: {
            [STAGE_VALUE_TYPES.TANK_CU_COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
            [STAGE_VALUE_TYPES.TANK_FLOWRATE]: VALUE_STATUS.HIDDEN,
        },
    },
    [STAGE_TYPES.WASHER]: {
        [DATASET_MODES.DESIGN_MODE]: {
            [STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.POST_WASH_COMPOSITION]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.WASH_OFFSET]: VALUE_STATUS.COMPUTE_USING,
        },
        [DATASET_MODES.ANALYSIS_MODE]: {
            [STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
            [STAGE_VALUE_TYPES.POST_WASH_COMPOSITION]: VALUE_STATUS.COMPUTE_USING,
            [STAGE_VALUE_TYPES.WASH_OFFSET]: VALUE_STATUS.COMPUTE_USING,
        },
    },
    [STAGE_TYPES.EXTRACT]: {
        // Compute stage efficiency:
        [ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY]: {
            [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE,
        },
        // compute option: recovery-raffinate
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.RECOVERY_RAFFINATE]: {
            // compute using option: oa ratio
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.OA_RATIO]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE_USING,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
            // compute using option: flowrates
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.FLOWRATES]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
        },
        // compute option: oa ratio
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.OA_RATIO]: {
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.RECOVERY_PERCENT]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
            // compute using option: flowrates
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.RAFFINATE]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
        },
        // compute option: reagent concentration
        [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE.REAGENT_CONCENTRATION]: {
            // compute using option: recovery
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.OA_RATIO]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE_USING,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
            // compute using option: flowrates
            [DESIGN_PRESET_TYPES.EXTRACT.COMPUTE_USING.FLOWRATES]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
        },
    },
    [STAGE_TYPES.STRIP]: {
        // Compute stage efficiency:
        [ANALYSIS_PRESET_TYPES.STAGE_EFFICIENCY]: {
            [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE,
        },
        // compute option: oa ratio
        [DESIGN_PRESET_TYPES.STRIP.COMPUTE.OA_RATIO]: {
            [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
            [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
        },
        // compute option: advance
        [DESIGN_PRESET_TYPES.STRIP.COMPUTE.ADVANCE]: {
            // compute using option: oa ratio
            [DESIGN_PRESET_TYPES.STRIP.COMPUTE_USING.OA_RATIO]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE_USING,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
            // compute using option: flowrates
            [DESIGN_PRESET_TYPES.STRIP.COMPUTE_USING.FLOWRATES]: {
                [STAGE_VALUE_TYPES.OA_RATIO]: VALUE_STATUS.COMPUTE,
                [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: VALUE_STATUS.COMPUTE_USING,
            },
        },
    },
};

// 2d, 3D Sensitivity

export const SENSITIVITY_OPTIONS = {
    REAGENT_CONCENTRATION: 'REAGENT_CONCENTRATION',
    OXMOD_RATIO: 'OXMOD_RATIO',
    EXTRACT_OA: 'EXTRACT_OA',
    PLS_COMPOSITION: 'PLS_COMPOSITION',
    PLS_PH: 'PLS_PH',
    ADVANCE_ELECTROLYTE: 'ADVANCE_ELECTROLYTE',
    LEAN_ELECTROLYTE: 'LEAN_ELECTROLYTE',
    ACID: 'ACID',
};

export const SENSITIVITY_OPTION_LIMITS = {
    [SENSITIVITY_OPTIONS.REAGENT_CONCENTRATION]: {
        MINIMUM: 0.1,
        MAXIMUM: 100,
        DEFAULT_MINIMUM: 5, // Should come from the reagent.
        DEFAULT_MAXIMUM: 45,
    },
    [SENSITIVITY_OPTIONS.OXMOD_RATIO]: {
        MINIMUM: 0.1,
        MAXIMUM: 10,
        DEFAULT_MINIMUM: 0.5, // Should come from the oxime.
        DEFAULT_MAXIMUM: 3,
    },
    [SENSITIVITY_OPTIONS.EXTRACT_OA]: {
        MINIMUM: 0.1,
        MAXIMUM: 30,
        DEFAULT_MINIMUM: 1,
        DEFAULT_MAXIMUM: 2,
    },
    [SENSITIVITY_OPTIONS.PLS_COMPOSITION]: {
        MINIMUM: 0.001,
        MAXIMUM: null,
        DEFAULT_MINIMUM: 3,
        DEFAULT_MAXIMUM: 5,
    },
    [SENSITIVITY_OPTIONS.PLS_PH]: {
        MINIMUM: 0.1,
        MAXIMUM: 15,
        DEFAULT_MINIMUM: 2,
        DEFAULT_MAXIMUM: 3,
    },
    // TODO: Check dataset mode to be in Analysis Mode
    [SENSITIVITY_OPTIONS.ADVANCE_ELECTROLYTE]: {
        MINIMUM: 0.001,
        MAXIMUM: null,
        DEFAULT_MINIMUM: 30,
        DEFAULT_MAXIMUM: 45,
    },
    [SENSITIVITY_OPTIONS.LEAN_ELECTROLYTE]: {
        MINIMUM: 0.1,
        MAXIMUM: null,
        DEFAULT_MINIMUM: 35,
        DEFAULT_MAXIMUM: 45,
    },
    [SENSITIVITY_OPTIONS.ACID]: {
        MINIMUM: 0.1,
        MAXIMUM: 500,
        DEFAULT_MINIMUM: 180,
        DEFAULT_MAXIMUM: 210,
    },
};

export const THREE_D_SENSITIVITY_OUTPUT_OPTIONS = {
    OVERALL_RECOVERY: 'OVERALL_RECOVERY',
    NET_TRANSFER: 'NET_TRANSFER',
};

/**
 * Determines the level of precision for the inputs for the 3D Sensitivity Plot
 */
export const THREE_D_SENSITIVITY_SIGNIFICANT_FIGURES = 3;

export const TWO_D_STEP_COUNT_VALUES = [5, 10, 25, 50, 100];

export const OXIME_CONCENTRATION_DATA_POINT_COUNT = 15;

// Advanced Stream options
export const ADVANCED_STREAMS_SETUP_TYPES = {
    FEED: 'FEED', // Electrolyte parallel steam option becomes advanced FEED stream setup type
    BLEND: 'BLEND',
    BLEED: 'BLEED',
    BLEED_BLEND: 'BLEED_BLEND',
    SKIP: 'SKIP',
    BYPASS: 'BYPASS',
    BYPASS_FEED: 'BYPASS_FEED',
    BYPASS_BLEED: 'BYPASS_BLEED',
    TANK_TO_AND_FROM_NEW_TANK: 'TANK_TO_AND_FROM_NEW_TANK',
    NONE: 'NONE',
};

export const PLS_STREAM_OPTIONS = {
    FEED: 'FEED',
    BLEND: 'BLEND',
    BLEED: 'BLEED',
    BLEED_BLEND: 'BLEED_BLEND',
    SKIP: 'SKIP',
};

export const ELECTROLYTE_STREAM_OPTIONS = {
    SERIES: 'SERIES',
    PARALLEL: 'PARALLEL', // Electrolyte parallel steam option becomes advanced FEED stream setup type
};

export const ORGANIC_IN_EXTRACT_STREAM_OPTIONS = {
    DIRECT: 'DIRECT', // Organic direct steam option becomes NONE as advanced stream setup type
    BYPASS: 'BYPASS',
    BYPASS_BLEND: 'BYPASS_BLEND',
};

export const ORGANIC_IN_STRIP_STREAM_OPTIONS = {
    DIRECT: 'DIRECT', // Organic direct steam option becomes NONE as advanced stream setup type
    BYPASS: 'BYPASS',
};

export const NUMBER_INPUT_PLACEHOLDER = '—';
export const NO_RESULT_STRING = '—';

export const SUCCESS_TYPE = 'SUCCESS';
export const ERROR_TYPE = 'ERROR';
export const INFO_TYPE = 'INFO';

export const TWOD_EXPORT_DATA_KEYS = {
    PLS_STREAMS: [
        'plsStreamsComposition',
        'plsStreamsPh',
        'plsStreamsFlowrate',
        'plsStreamsRaffinate',
        'plsStreamsRaffinateFlowrate',
        'plsStreamsRecoveryPercent',
        'plsStreamsOARatio',
        'plsStreamsStageEfficiency',
    ],
    ELECTROLYTE_STREAMS: [
        'electrolyteStreamsSpentElectrolyte',
        'electrolyteStreamsSpentAcid',
        'electrolyteStreamsFlowrate',
        'electrolyteStreamsAdvanceElectrolyte',
        'electrolyteStreamsOARatio',
        'electrolyteStreamsStageEfficiency',
    ],
    ORGANIC_STREAMS: [
        'organicStreamsFlowrate',
        'organicStreamsPercentLoading',
        'organicStreamsPercentStripped',
        'organicStreamsNetTransfer',
    ],
    CIRCUIT_INFORMATION: [
        'circuitInformationReagentConcentration',
        'circuitInformationOxModRatio',
        'circuitInformationOverallRecovery',
        'circuitInformationExtractStageCount',
        'circuitInformationStripStageCount',
    ],
};

// Plant Statuses
export const PLANT_STATUSES = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

export const TRENDS_PERIODS = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
};

// Define default trend period, use monthly for non-prod as data tends not to be always up to date
export const DEFAULT_PERIOD = TRENDS_PERIODS.WEEKLY;

export const KPI_SPECIFICITY_TYPES = {
    PLANT: 'PLANT',
    CIRCUIT: 'CIRCUIT',
    STAGE: 'STAGE',
    STREAM: 'STREAM',
    CASCADE: 'CASCADE',
};

export const CIRCUIT_TYPES = {
    MINCHEM_CIRCUIT: 'MINCHEM_CIRCUIT',
    SOLVEXTRACT_CIRCUIT: 'SOLVEXTRACT_CIRCUIT',
};

export const MINOR_KPI_SECTIONS = {
    EXTRACTION: 'EXTRACTION',
    STRIPPING: 'STRIPPING',
    ORGANIC: 'ORGANIC',
    STAGE_EFFICIENCIES: 'STAGE_EFFICIENCIES',
    FLOW: 'FLOW',
    ELECTROWINNING: 'ELECTROWINNING',
    IMPURITY: 'IMPURITY',
    MIXING: 'MIXING',
    PHYSICAL_MEASUREMENTS: 'PHYSICAL_MEASUREMENTS',
    OTHER: 'OTHER',
};

// Used to make context changes to KPISetupTable Component...
export const KPI_SETUP_TABLE_CONTEXT_TYPES = {
    CIRCUIT_MAJOR_KPIS: 'CIRCUIT_MAJOR_KPIS',
    CIRCUIT_MINOR_KPIS: 'CIRCUIT_MINOR_KPIS',
    PLANT_KPIS: 'PLANT_KPIS',
};

// TODO: Convert this object into an array. Use index instead.
export const MINOR_KPI_SECTIONS_ORDER = {
    [MINOR_KPI_SECTIONS.FLOW]: 0,
    [MINOR_KPI_SECTIONS.EXTRACTION]: 1,
    [MINOR_KPI_SECTIONS.STRIPPING]: 2,
    [MINOR_KPI_SECTIONS.ORGANIC]: 3,
    [MINOR_KPI_SECTIONS.STAGE_EFFICIENCIES]: 4,
    [MINOR_KPI_SECTIONS.ELECTROWINNING]: 5,
    [MINOR_KPI_SECTIONS.MIXING]: 6,
    [MINOR_KPI_SECTIONS.PHYSICAL_MEASUREMENTS]: 7,
    [MINOR_KPI_SECTIONS.IMPURITY]: 8,
    [MINOR_KPI_SECTIONS.OTHER]: 9,
};

export const PLANT_MINOR_KPI_TYPES_PER_KPI_SECTION = {
    [MINOR_KPI_SECTIONS.FLOW]: [
        PLANT_VALUE_TYPES.TOTAL_PLS_FLOWRATE,
        PLANT_VALUE_TYPES.TOTAL_ELECTROLYTE_FLOWRATE,
        PLANT_VALUE_TYPES.TOTAL_ORGANIC_FLOWRATE,
        PLANT_VALUE_TYPES.TOTAL_ACID_MAKEUP,
        PLANT_VALUE_TYPES.TOTAL_WATER_MAKEUP,
        PLANT_VALUE_TYPES.TOTAL_WASH_WATER,
    ],
    [MINOR_KPI_SECTIONS.EXTRACTION]: [
        PLANT_VALUE_TYPES.TOTAL_RAFFINATE,
        PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER,
        PLANT_VALUE_TYPES.OVERALL_RECOVERY,
    ],
    [MINOR_KPI_SECTIONS.STRIPPING]: [
        PLANT_VALUE_TYPES.LEAN_CL,
        PLANT_VALUE_TYPES.LEAN_CO,
        PLANT_VALUE_TYPES.LEAN_FE,
        PLANT_VALUE_TYPES.LEAN_MN,
        PLANT_VALUE_TYPES.LEAN_NITRATE,
        PLANT_VALUE_TYPES.LEAN_BLEED,
        PLANT_VALUE_TYPES.COMMERCIAL_RICH_CU,
    ],
    [MINOR_KPI_SECTIONS.ORGANIC]: [
        PLANT_VALUE_TYPES.REAGENT_TANK_LEVEL,
        PLANT_VALUE_TYPES.REAGENT_TANK_LEVEL_2,
        PLANT_VALUE_TYPES.DILUENT_TANK_LEVEL,
        PLANT_VALUE_TYPES.DILUENT_TANK_LEVEL_2,
        PLANT_VALUE_TYPES.REAGENT_ADDITION,
        PLANT_VALUE_TYPES.DILUENT_ADDITION,
    ],
    [MINOR_KPI_SECTIONS.ELECTROWINNING]: [
        PLANT_VALUE_TYPES.CURRENT,
        PLANT_VALUE_TYPES.CURRENT_EFFICIENCY,
        PLANT_VALUE_TYPES.CURRENT_DENSITY,
        PLANT_VALUE_TYPES.CELLS_HARVESTED,
        PLANT_VALUE_TYPES.CU_HARVESTED,
        PLANT_VALUE_TYPES.CELL_ELECTROLYTE_TEMPERATURE,
    ],
    [MINOR_KPI_SECTIONS.IMPURITY]: [
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_MG_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_AL_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_FE_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_MN_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_CL_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_CO_BLEED,
        PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_NITRATE_BLEED,
    ],
    [MINOR_KPI_SECTIONS.STAGE_EFFICIENCIES]: [],
    [MINOR_KPI_SECTIONS.PHYSICAL_MEASUREMENTS]: [],
    [MINOR_KPI_SECTIONS.OTHER]: [],
};

// Force the following circuit KPIs in the sections provided
export const CIRCUIT_MINOR_KPI_TYPES_PER_KPI_SECTION = {
    [MINOR_KPI_SECTIONS.FLOW]: [
        STREAM_VALUE_TYPES.FLOWRATE,
        STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
        STREAM_VALUE_TYPES.ACID_MAKEUP,
        STREAM_VALUE_TYPES.ACID_MAKEUP_TREND,
        STREAM_VALUE_TYPES.WATER_MAKEUP,
        STREAM_VALUE_TYPES.WATER_MAKEUP_TREND,

        STAGE_VALUE_TYPES.TANK_FLOWRATE,
        STAGE_VALUE_TYPES.WASH_WATER,
    ],
    [MINOR_KPI_SECTIONS.EXTRACTION]: [
        // Stream Values:
        DATASET_VALUE_TYPES.PLS_MASS_BALANCE,
        STREAM_VALUE_TYPES.PRE_PLS_CU,
        STREAM_VALUE_TYPES.PRE_PH,
        STREAM_VALUE_TYPES.FIXED_PH,
    ],
    [MINOR_KPI_SECTIONS.STRIPPING]: [DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE],
    [MINOR_KPI_SECTIONS.ORGANIC]: [
        // Dataset Values
        DATASET_VALUE_TYPES.MAX_LOAD,
        DATASET_VALUE_TYPES.MAX_LOAD_OFFSET,
        DATASET_VALUE_TYPES.OXIME_GPL,
        DATASET_VALUE_TYPES.OXIME_RATIO,
        DATASET_VALUE_TYPES.ALDOXIME_PERCENT,
        DATASET_VALUE_TYPES.KETOXIME_PERCENT,
        DATASET_VALUE_TYPES.REAGENT_CONCENTRATION,
        DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE,

        // Stream Values:

        STREAM_VALUE_TYPES.NET_TRANSFER,
        STREAM_VALUE_TYPES.LOADED_PERCENT,
        STREAM_VALUE_TYPES.STRIPPED_PERCENT,

        // Stage Values:
        STAGE_VALUE_TYPES.WASH_OFFSET,
        STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION,
        STAGE_VALUE_TYPES.POST_WASH_COMPOSITION,
        STAGE_VALUE_TYPES.TANK_CU_COMPOSITION,
    ],
    [MINOR_KPI_SECTIONS.MIXING]: [
        STAGE_VALUE_TYPES.PRIMARY_MIXER_SPEED,
        STAGE_VALUE_TYPES.PRIMARY_MIXER_OA_RATIO,
        STAGE_VALUE_TYPES.PRIMARY_MIXER_RETENTION_TIME,
    ],
    [MINOR_KPI_SECTIONS.STAGE_EFFICIENCIES]: [
        // Only stage efficies should be in the stage efficiencies section
        STAGE_VALUE_TYPES.STAGE_EFFICIENCY,
    ],
    [MINOR_KPI_SECTIONS.IMPURITY]: [
        STREAM_VALUE_TYPES.MG_COMPOSITION,
        STREAM_VALUE_TYPES.AL_COMPOSITION,
        STREAM_VALUE_TYPES.FE_COMPOSITION,
        STREAM_VALUE_TYPES.MN_COMPOSITION,
        STREAM_VALUE_TYPES.CL_COMPOSITION,
        STREAM_VALUE_TYPES.CO_COMPOSITION,
        STREAM_VALUE_TYPES.NITRATE_COMPOSITION,

        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MG,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AL,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MN,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CL,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_FE,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CO,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_NITRATE,

        STREAM_VALUE_TYPES.TOTAL_AL_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_MG_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_FE_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_MN_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_CL_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_CO_WASHED_PERCENT,
        STREAM_VALUE_TYPES.TOTAL_NITRATE_WASHED_PERCENT,

        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AL,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MG,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_FE,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MN,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CL,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CO,
        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_NITRATE,

        STREAM_VALUE_TYPES.TOTAL_AL_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_MG_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_FE_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_MN_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_CL_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_CO_TRANSFER,
        STREAM_VALUE_TYPES.TOTAL_NITRATE_TRANSFER,

        STREAM_VALUE_TYPES.PHYSICAL_FE_TRANSFER,
        STREAM_VALUE_TYPES.CHEMICAL_FE_TRANSFER,
        STREAM_VALUE_TYPES.ESTIMATED_ORGANIC_FE,

        STREAM_VALUE_TYPES.LEAN_MG_BLED,
        STREAM_VALUE_TYPES.LEAN_AL_BLED,
        STREAM_VALUE_TYPES.LEAN_MN_BLED,
        STREAM_VALUE_TYPES.LEAN_CL_BLED,
        STREAM_VALUE_TYPES.LEAN_FE_BLED,
        STREAM_VALUE_TYPES.LEAN_CO_BLED,
        STREAM_VALUE_TYPES.LEAN_NITRATE_BLED,

        STREAM_VALUE_TYPES.REQUIRED_LEAN_MG_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_AL_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_FE_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_MN_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_CL_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_CO_BLEED,
        STREAM_VALUE_TYPES.REQUIRED_LEAN_NITRATE_BLEED,

        STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE,

        STREAM_VALUE_TYPES.LEAN_FE_MN_RATIO,
        STREAM_VALUE_TYPES.STRIPPED_FE_MN_RATIO,
    ],
    [MINOR_KPI_SECTIONS.PHYSICAL_MEASUREMENTS]: [
        STAGE_VALUE_TYPES.SETTLER_ORGANIC_PHASE_VELOCITY,
        STAGE_VALUE_TYPES.SETTLER_AQUEOUS_PHASE_VELOCITY,
        STAGE_VALUE_TYPES.SETTLER_ORGANIC_RETENTION_TIME,
        STAGE_VALUE_TYPES.SETTLER_AQUEOUS_RETENTION_TIME,
        STAGE_VALUE_TYPES.SETTLER_SPECIFIC_FLOW,
        STAGE_VALUE_TYPES.ORGANIC_DEPTH,
        STAGE_VALUE_TYPES.CRUD_DEPTH,
        DATASET_VALUE_TYPES.TSS,
    ],
    [MINOR_KPI_SECTIONS.OTHER]: [],
};

export const DATASET_STATUSES = {
    INVALID: 'INVALID',
    CONVERGED: 'CONVERGED',
    DIVERGED: 'DIVERGED',
    NOT_COMPUTED_YET: 'NOT_COMPUTED_YET',
    SOME_INVALID: 'SOME_INVALID',
    SOME_DIVERGED: 'SOME_DIVERGED',
    SOME_NOT_COMPUTED_YET: 'SOME_NOT_COMPUTED_YET',
    ALL_CONVERGED: 'ALL_CONVERGED',
};

/* Trends */
export const TRENDS_PAGE_TYPE = {
    CIRCUIT: 'CIRCUIT',
    PLANT: 'PLANT',
};

export const MAJOR_KPIS = {
    [TRENDS_PAGE_TYPE.CIRCUIT]: [
        DATASET_VALUE_TYPES.OVERALL_RECOVERY,
        DATASET_VALUE_TYPES.CU_TRANSFERRED,
    ],
    [TRENDS_PAGE_TYPE.PLANT]: [
        PLANT_VALUE_TYPES.OVERALL_RECOVERY,
        PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER,
    ],
};

export const RECOMMENDATION_NEW_ID = 'new';

export const NEW_TANK_OPTION = 'NEW_TANK';

export const RECOMMENDATION_FEEDBACK_TYPES = {
    WILL_DO_IT: 'WILL_DO_IT',
    OTHER: 'OTHER',
    DECLINE: 'DECLINE',
};

export const DECISION_TREE_RECOVERY_TYPES = {
    HIGH_RECOVERY: 'HIGH_RECOVERY',
    LOW_RECOVERY: 'LOW_RECOVERY',
};
export const DECISION_TREE_OPTIONS = {
    [DECISION_TREE_RECOVERY_TYPES.HIGH_RECOVERY]: {
        ORGANIC_FLOWRATE: 'ORGANIC_FLOWRATE',
        ADD_DILUENT: 'ADD_DILUENT',
        FLOW_DISTRIBUTION: 'FLOW_DISTRIBUTION',
    },
    [DECISION_TREE_RECOVERY_TYPES.LOW_RECOVERY]: {
        ORGANIC_FLOWRATE: 'ORGANIC_FLOWRATE',
        LEAN_FLOWRATE: 'LEAN_FLOWRATE',
        LEAN_ACID: 'LEAN_ACID',
        ADD_REAGENT: 'ADD_REAGENT',
        FLOW_DISTRIBUTION: 'FLOW_DISTRIBUTION',
        LEAN_CU: 'LEAN_CU',
        REVIEW_MIXER_SPEEDS: 'REVIEW_MIXER_SPEEDS',
    },
};

export const DEFAULT_MAXIMUM_REAGENT_CONCENTRATION_DELTA = 2;
export const DEFAULT_MAXIMUM_RECOMMENDATIONS = 3;

/**
 * Computed stream values significant figures for rounding up
 * Used to round up displayed computed values in MimicDiagram
 */
export const STREAM_VALUES_SIGNIFICANT_FIGURES = {
    [STREAM_VALUE_TYPES.FLOWRATE]: 2,
    [STREAM_VALUE_TYPES.COMPOSITION]: 2,
    [STREAM_VALUE_TYPES.SPENT]: 2,
    [STREAM_VALUE_TYPES.ACID]: 2,
    [STREAM_VALUE_TYPES.ADVANCE]: 2,
    [STREAM_VALUE_TYPES.PLS]: 3,
    [STREAM_VALUE_TYPES.PH]: 2,
    [STREAM_VALUE_TYPES.RAFFINATE]: 4,
    [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: 2,
    [STREAM_VALUE_TYPES.LOADED_PERCENT]: 2,
    [STREAM_VALUE_TYPES.NET_TRANSFER]: 3,
    [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: 2,
    [STREAM_VALUE_TYPES.PRE_COMPOSITION]: 2,
    [STREAM_VALUE_TYPES.POST_COMPOSITION]: 2,
    [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: 2,
};

export const KPI_SETTINGS_PRECISION_OPTIONS = [0, 1, 2, 3, 4];

export const DATASET_SCENARIO_PRECISION_OVERRIDE = {
    [DATASET_VALUE_TYPES.CU_TRANSFERRED]: 1,
};

export const PLANT_CREATION_STEPS = {
    PLANT: 'PLANT',
    KPIS: 'KPIS',
    REVIEW: 'REVIEW',
    DONE: 'DONE',
};

export const CIRCUIT_ELEVATION_STEPS = {
    PLANT: 'PLANT',
    MAJOR_KPIS: 'MAJOR_KPIS',
    MINOR_KPIS: 'MINOR_KPIS',
    DECISION_TREE: 'DECISION_TREE',
    REVIEW: 'REVIEW',
    DONE: 'DONE',
};

export const STREAM_ARROW_FORWARD = '→';

export const KPI_SETTING_PROPERTIES = {
    BASE: {
        streamId: null,
        stageId: null,
        name: '',
        kpiUnit: null,
        show: true,
        displayInPlant: false,
        precision: 0,
        order: 0, // will be overwritten.
        minValid: null,
        maxValid: null,
        lowTarget: null,
        highTarget: null,
        section: MINOR_KPI_SECTIONS.OTHER, // Default value, override per KPI
        isRequired: false,
        isForcedRequire: false, // TODO MS-431: Determine if KPI should be forceRequired
        isUndeletable: false, // kpi cannot be deleted even if it is not required.
        isDefault: false, // if the kpi is the default then it is shown in the circuit elevation by default but may be customized.
        disableKpiTypeInput: true, // informs kpiSetupTable if the kpi type input should be disabled or not
        disableKpiContextInput: true,
    },
    WITH_MAIN_TARGET: {
        mainTarget: null,
    },
    RECOMMENDABLE: {
        minRecommend: null,
        maxRecommend: null,
        roundTo: 0, // no rounding
    },
};

export const KPI_TYPES_WITH_RECOMMENDATIONS = [
    STREAM_VALUE_TYPES.FLOWRATE,
    STREAM_VALUE_TYPES.FLOWRATE_PERCENT,
    STREAM_VALUE_TYPES.SPENT,
    STREAM_VALUE_TYPES.ACID,
    STAGE_VALUE_TYPES.TANK_FLOWRATE, // organic flow rate on the terminal LO side
    DATASET_VALUE_TYPES.REAGENT_CONCENTRATION,
];

export const KPI_TYPES_WITH_MAIN_TARGET = [
    STAGE_VALUE_TYPES.STAGE_EFFICIENCY,
    DATASET_VALUE_TYPES.OVERALL_RECOVERY,
    DATASET_VALUE_TYPES.CU_TRANSFERRED,

    STREAM_VALUE_TYPES.MG_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.AL_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.FE_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.MN_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.CL_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.CO_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:
    STREAM_VALUE_TYPES.NITRATE_COMPOSITION, // Technically this should only be for lean feed compositions :thinking:

    STREAM_VALUE_TYPES.FIXED_PH,
];

export const KPI_UNIT_TYPES = {
    PERCENT: 'PERCENT', // %
    MASS: 'MASS', // mg, g, kg, tonne, pounds, etc
    CURRENT: 'CURRENT', // A
    FREQUENCY: 'FREQUENCY', // Hz, rpm, (% possible)
    DISTANCE: 'DISTANCE', // in, ft, cm, m
    TEMPERATURE: 'TEMPERATURE', // F°, C°
    AREA: 'AREA', // m^3, GALLON, litre
    VOLUME: 'VOLUME', // m^3, GALLON, litre
    TIME: 'TIME', // seconds, minutes, days etc.
};

export const COMPLEX_KPI_UNIT_TYPES = {
    DENSITY: 'DENSITY', // Mass per volume, g/L, mg/L, lbs/m^2
    VOLUME_FLOW_RATE: 'VOLUME_FLOW_RATE', // Volume per time, M^3/h, gal/min
    MASS_FLOW_RATE: 'MASS_FLOW_RATE', // Mass per time, kg/day, gram/min
    CURRENT_DENSITY: 'CURRENT_DENSITY', // Current per area: A/ft^2, A/m^2
    VELOCITY: 'VELOCITY', // distance over time M/h. cm/s
    SSF: 'SSF', // Technically this is velocity, but industry stand is M^3/hour/M^2
};

/**
 * DO NOT INCLUDE ANY SLASHES "/" IN UNIT NAMES.
 */
export const UNIT_NAMES = {
    PERCENT: 'PERCENT',
    MILLIGRAM: 'MILLIGRAM',
    GRAM: 'GRAM',
    KILOGRAM: 'KILOGRAM',
    HALF_SHORT_TON: 'HALF_SHORT_TON', // 1000 lbs
    METRIC_TON: 'METRIC_TON', // 1 ton

    AMPS: 'AMPS',

    PARTS_PER_MILLION: 'PARTS_PER_MILLION',

    RPM: 'RPM',
    HERTZ: 'HERTZ',

    CENTIMETER: 'CENTIMETER',
    METER: 'METER',
    INCH: 'INCH',
    FEET: 'FEET',

    CELCIUS: 'CELCIUS',
    FAHRENHEIT: 'FAHRENHEIT',

    CUBIC_METER: 'CUBIC_METER',
    GALLON: 'GALLON',
    LITRE: 'LITRE',

    DAY: 'DAY',
    HOUR: 'HOUR',
    MINUTE: 'MINUTE',
    SECOND: 'SECOND',

    SQUARE_FEET: 'SQUARE_FEET',
    SQUARE_METER: 'SQUARE_METER',
};

/**
 * The first item in each array is the default value.
 */
export const UNITS_FOR_UNIT_TYPE = {
    [KPI_UNIT_TYPES.PERCENT]: [UNIT_NAMES.PERCENT],
    [KPI_UNIT_TYPES.MASS]: [
        UNIT_NAMES.GRAM, // metric
        UNIT_NAMES.KILOGRAM,
        UNIT_NAMES.MILLIGRAM,
        UNIT_NAMES.METRIC_TON,
        UNIT_NAMES.HALF_SHORT_TON, // us
    ],
    [KPI_UNIT_TYPES.CURRENT]: [UNIT_NAMES.AMPS],
    [KPI_UNIT_TYPES.FREQUENCY]: [UNIT_NAMES.RPM, UNIT_NAMES.HERTZ, UNIT_NAMES.PERCENT],
    [KPI_UNIT_TYPES.DISTANCE]: [
        UNIT_NAMES.METER, // metric
        UNIT_NAMES.CENTIMETER,
        UNIT_NAMES.FEET, // us
        UNIT_NAMES.INCH,
        UNIT_NAMES.PERCENT, // Use wisely, currently only Organic Tanks can use the percent distance.
    ],
    [KPI_UNIT_TYPES.TEMPERATURE]: [UNIT_NAMES.CELCIUS, UNIT_NAMES.FAHRENHEIT],
    [KPI_UNIT_TYPES.VOLUME]: [UNIT_NAMES.CUBIC_METER, UNIT_NAMES.LITRE, UNIT_NAMES.GALLON],
    [KPI_UNIT_TYPES.AREA]: [UNIT_NAMES.SQUARE_FEET, UNIT_NAMES.SQUARE_METER],
    [KPI_UNIT_TYPES.TIME]: [UNIT_NAMES.HOUR, UNIT_NAMES.DAY, UNIT_NAMES.MINUTE, UNIT_NAMES.SECOND],

    // NOTE: For complex types, see the `addComplexKpiUnitTypes` function
    [COMPLEX_KPI_UNIT_TYPES.DENSITY]: [],
    [COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE]: [],
    [COMPLEX_KPI_UNIT_TYPES.VELOCITY]: [],
    [COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE]: [],
    [COMPLEX_KPI_UNIT_TYPES.CURRENT_DENSITY]: [],
    [COMPLEX_KPI_UNIT_TYPES.SSF]: [],
};
/**
 * Add the complex types to the UNITS_FOR_UNIT_TYPE object.
 */
(function addComplexKpiUnitTypes() {
    function combine(array1: Array<string>, array2: Array<string>): Array<any> {
        const combos = [];
        array1.forEach((val1: string) =>
            array2.forEach((val2: string) => combos.push([val1, val2]))
        );
        return combos;
    }
    function combine3(
        array1: Array<string>,
        array2: Array<string>,
        array3: Array<string>
    ): Array<any> {
        const combos = [];
        array1.forEach((val1: string) => combine(array2, array3).map((elem) => elem.push(val1)));
        return combos;
    }
    // DENSITY:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.DENSITY] = combine(
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.MASS],
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.VOLUME]
    );
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.DENSITY].push(UNIT_NAMES.PARTS_PER_MILLION);
    // VOLUME FLOW RATE:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE] = combine(
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.VOLUME],
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.TIME]
    );
    // VELOCITY:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.VELOCITY] = combine(
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.DISTANCE],
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.TIME]
    );
    // MASS FLOW RATE:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE] = combine(
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.MASS],
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.TIME]
    );
    // CURRENT DENSITY:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.CURRENT_DENSITY] = combine(
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.CURRENT],
        UNITS_FOR_UNIT_TYPE[KPI_UNIT_TYPES.AREA]
    );
    // SSF is technically a velocity but the industry standard is to report in Meter^3 / Hour / Meter^2
    // We can let the admin decide what to display.
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.SSF] =
        // must recreate array to avoid contamination of the velocity units
        [...UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.VELOCITY]];
    // US standard:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.SSF].unshift([
        UNIT_NAMES.GALLON,
        UNIT_NAMES.MINUTE,
        UNIT_NAMES.SQUARE_FEET,
    ]);
    // metric standard:
    UNITS_FOR_UNIT_TYPE[COMPLEX_KPI_UNIT_TYPES.SSF].unshift([
        UNIT_NAMES.CUBIC_METER,
        UNIT_NAMES.HOUR,
        UNIT_NAMES.SQUARE_METER,
    ]);
})();
export const COMPLEX_KPI_UNIT_DELIMITER = '/'; // not used in translations.

/**
 * Get the unit type for each KPI type
 */
export const KPI_TYPE_TO_UNIT_TYPE = {
    [STREAM_VALUE_TYPES.FLOWRATE]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.SPENT]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.ACID]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.ACID_MAKEUP]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.WATER_MAKEUP]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.ACID_MAKEUP_TREND]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.WATER_MAKEUP_TREND]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.ADVANCE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.PLS]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.PRE_PLS_CU]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.PH]: null,
    [STREAM_VALUE_TYPES.FIXED_PH]: null,
    [STREAM_VALUE_TYPES.PRE_PH]: null,
    [STREAM_VALUE_TYPES.RAFFINATE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.RECOVERY_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.LOADED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.NET_TRANSFER]: null,
    [STREAM_VALUE_TYPES.STRIPPED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.PRE_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.POST_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.FLOWRATE_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STAGE_VALUE_TYPES.WASH_WATER]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AVERAGE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,

    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_AL]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MG]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_FE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_MN]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CL]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_CO]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.AINO_ENTRAINMENT_NITRATE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,

    [STREAM_VALUE_TYPES.AL_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.MG_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.FE_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.MN_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.CL_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.CO_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.NITRATE_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,

    [STREAM_VALUE_TYPES.TOTAL_AL_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_MG_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_FE_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_MN_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_CL_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_CO_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.TOTAL_NITRATE_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,

    [STREAM_VALUE_TYPES.TOTAL_AL_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_MG_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_FE_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_MN_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_CL_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_CO_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.TOTAL_NITRATE_WASHED_PERCENT]: KPI_UNIT_TYPES.PERCENT,

    [STREAM_VALUE_TYPES.LEAN_MG_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_AL_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_MN_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_CL_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_FE_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_CO_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [STREAM_VALUE_TYPES.LEAN_NITRATE_BLED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,

    [STREAM_VALUE_TYPES.REQUIRED_LEAN_MG_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_AL_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_MN_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_CL_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_FE_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_CO_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STREAM_VALUE_TYPES.REQUIRED_LEAN_NITRATE_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,

    [STREAM_VALUE_TYPES.PHYSICAL_FE_TRANSFER]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.CHEMICAL_FE_TRANSFER]: KPI_UNIT_TYPES.PERCENT,
    [STREAM_VALUE_TYPES.LEAN_FE_MN_RATIO]: null,
    [STREAM_VALUE_TYPES.STRIPPED_FE_MN_RATIO]: null,
    [STREAM_VALUE_TYPES.ESTIMATED_ORGANIC_FE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STREAM_VALUE_TYPES.BARREN_ORGANIC_TANK_LEVEL]: KPI_UNIT_TYPES.DISTANCE,

    [DATASET_VALUE_TYPES.REAGENT_CONCENTRATION]: KPI_UNIT_TYPES.PERCENT,
    [DATASET_VALUE_TYPES.OXIME_RATIO]: null,
    [DATASET_VALUE_TYPES.MAX_LOAD]: null,
    [DATASET_VALUE_TYPES.MAX_LOAD_OFFSET]: null,
    [DATASET_VALUE_TYPES.OXIME_GPL]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [DATASET_VALUE_TYPES.ALDOXIME_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [DATASET_VALUE_TYPES.KETOXIME_PERCENT]: KPI_UNIT_TYPES.PERCENT,
    [DATASET_VALUE_TYPES.CU_TRANSFERRED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [DATASET_VALUE_TYPES.ELECTROLYTE_MASS_BALANCE]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [DATASET_VALUE_TYPES.ORGANIC_MASS_BALANCE]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [DATASET_VALUE_TYPES.PLS_MASS_BALANCE]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [DATASET_VALUE_TYPES.OVERALL_RECOVERY]: KPI_UNIT_TYPES.PERCENT,
    [DATASET_VALUE_TYPES.TSS]: COMPLEX_KPI_UNIT_TYPES.DENSITY,

    [STAGE_VALUE_TYPES.STAGE_EFFICIENCY]: KPI_UNIT_TYPES.PERCENT,
    [STAGE_VALUE_TYPES.OA_RATIO]: null,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_SPEED]: KPI_UNIT_TYPES.FREQUENCY,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_OA_RATIO]: null,
    [STAGE_VALUE_TYPES.PRIMARY_MIXER_RETENTION_TIME]: KPI_UNIT_TYPES.TIME,
    [STAGE_VALUE_TYPES.ORGANIC_DEPTH]: KPI_UNIT_TYPES.DISTANCE,
    [STAGE_VALUE_TYPES.SETTLER_ORGANIC_PHASE_VELOCITY]: COMPLEX_KPI_UNIT_TYPES.VELOCITY,
    [STAGE_VALUE_TYPES.SETTLER_AQUEOUS_PHASE_VELOCITY]: COMPLEX_KPI_UNIT_TYPES.VELOCITY,
    [STAGE_VALUE_TYPES.SETTLER_ORGANIC_RETENTION_TIME]: KPI_UNIT_TYPES.TIME,
    [STAGE_VALUE_TYPES.SETTLER_AQUEOUS_RETENTION_TIME]: KPI_UNIT_TYPES.TIME,
    [STAGE_VALUE_TYPES.SETTLER_SPECIFIC_FLOW]: COMPLEX_KPI_UNIT_TYPES.SSF,
    [STAGE_VALUE_TYPES.CRUD_DEPTH]: KPI_UNIT_TYPES.DISTANCE,

    [STAGE_VALUE_TYPES.TANK_CU_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STAGE_VALUE_TYPES.TANK_FLOWRATE]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [STAGE_VALUE_TYPES.TANK_LEVEL]: KPI_UNIT_TYPES.DISTANCE,

    [STAGE_VALUE_TYPES.WASH_OFFSET]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STAGE_VALUE_TYPES.PRE_WASH_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [STAGE_VALUE_TYPES.POST_WASH_COMPOSITION]: COMPLEX_KPI_UNIT_TYPES.DENSITY,

    [PLANT_VALUE_TYPES.OVERALL_RECOVERY]: KPI_UNIT_TYPES.PERCENT,
    [PLANT_VALUE_TYPES.OVERALL_CU_TRANSFER]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [PLANT_VALUE_TYPES.REAGENT_TANK_LEVEL]: KPI_UNIT_TYPES.DISTANCE,
    [PLANT_VALUE_TYPES.REAGENT_TANK_LEVEL_2]: KPI_UNIT_TYPES.DISTANCE,
    [PLANT_VALUE_TYPES.DILUENT_TANK_LEVEL]: KPI_UNIT_TYPES.DISTANCE,
    [PLANT_VALUE_TYPES.DILUENT_TANK_LEVEL_2]: KPI_UNIT_TYPES.DISTANCE,
    [PLANT_VALUE_TYPES.REAGENT_ADDITION]: KPI_UNIT_TYPES.VOLUME,
    [PLANT_VALUE_TYPES.DILUENT_ADDITION]: KPI_UNIT_TYPES.VOLUME,
    [PLANT_VALUE_TYPES.TOTAL_PLS_FLOWRATE]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_ELECTROLYTE_FLOWRATE]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_ORGANIC_FLOWRATE]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_RAFFINATE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.TOTAL_ACID_MAKEUP]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_WATER_MAKEUP]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.LEAN_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.LEAN_CL]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.LEAN_CO]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.LEAN_FE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.LEAN_MN]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.LEAN_NITRATE]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.COMMERCIAL_RICH_CU]: COMPLEX_KPI_UNIT_TYPES.DENSITY,
    [PLANT_VALUE_TYPES.CURRENT]: KPI_UNIT_TYPES.CURRENT,
    [PLANT_VALUE_TYPES.CURRENT_EFFICIENCY]: KPI_UNIT_TYPES.PERCENT,
    [PLANT_VALUE_TYPES.CURRENT_DENSITY]: COMPLEX_KPI_UNIT_TYPES.CURRENT_DENSITY,
    [PLANT_VALUE_TYPES.CELLS_HARVESTED]: null,
    [PLANT_VALUE_TYPES.CU_HARVESTED]: COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE,
    [PLANT_VALUE_TYPES.CELL_ELECTROLYTE_TEMPERATURE]: KPI_UNIT_TYPES.TEMPERATURE,
    [PLANT_VALUE_TYPES.TOTAL_WASH_WATER]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,

    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_MG_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_AL_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_MN_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_CL_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_FE_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_CO_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
    [PLANT_VALUE_TYPES.TOTAL_REQUIRED_LEAN_NITRATE_BLEED]: COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE,
};

export const LOCALITY_DEFAULT_UNITS_FOR_UNIT_TYPE = {
    [KPI_UNIT_TYPES.PERCENT]: null,
    [KPI_UNIT_TYPES.MASS]: null,
    [KPI_UNIT_TYPES.CURRENT]: null,
    [KPI_UNIT_TYPES.FREQUENCY]: null,
    [KPI_UNIT_TYPES.DISTANCE]: {
        [UNIT_TYPES.US]: UNIT_NAMES.FEET,
        [UNIT_TYPES.METRIC]: UNIT_NAMES.METER,
        [UNIT_TYPES.LITRE]: UNIT_NAMES.METER,
    },
    [KPI_UNIT_TYPES.TEMPERATURE]: {
        [UNIT_TYPES.US]: UNIT_NAMES.FAHRENHEIT,
        [UNIT_TYPES.METRIC]: UNIT_NAMES.CELCIUS,
        [UNIT_TYPES.LITRE]: UNIT_NAMES.CELCIUS,
    },
    [KPI_UNIT_TYPES.AREA]: null,
    [KPI_UNIT_TYPES.VOLUME]: {
        [UNIT_TYPES.US]: UNIT_NAMES.GALLON,
        [UNIT_TYPES.METRIC]: UNIT_NAMES.CUBIC_METER,
        [UNIT_TYPES.LITRE]: UNIT_NAMES.LITRE,
    },
    [KPI_UNIT_TYPES.TIME]: null,
    [COMPLEX_KPI_UNIT_TYPES.DENSITY]: {
        [UNIT_TYPES.US]: [UNIT_NAMES.GRAM, UNIT_NAMES.LITRE],
        [UNIT_TYPES.METRIC]: [UNIT_NAMES.GRAM, UNIT_NAMES.LITRE],
        [UNIT_TYPES.LITRE]: [UNIT_NAMES.GRAM, UNIT_NAMES.LITRE],
    },
    [COMPLEX_KPI_UNIT_TYPES.VOLUME_FLOW_RATE]: {
        [UNIT_TYPES.US]: [UNIT_NAMES.GALLON, UNIT_NAMES.MINUTE],
        [UNIT_TYPES.METRIC]: [UNIT_NAMES.CUBIC_METER, UNIT_NAMES.HOUR],
        [UNIT_TYPES.LITRE]: [UNIT_NAMES.LITRE, UNIT_NAMES.MINUTE],
    },
    [COMPLEX_KPI_UNIT_TYPES.MASS_FLOW_RATE]: {
        [UNIT_TYPES.US]: [UNIT_NAMES.HALF_SHORT_TON, UNIT_NAMES.DAY],
        [UNIT_TYPES.METRIC]: [UNIT_NAMES.METRIC_TON, UNIT_NAMES.DAY],
        [UNIT_TYPES.LITRE]: [UNIT_NAMES.METRIC_TON, UNIT_NAMES.DAY],
    },
    [COMPLEX_KPI_UNIT_TYPES.CURRENT_DENSITY]: null,
};

export const NOTIFICATION_TYPES = {
    SPM_KPI_TARGET_CHANGE: 'SPM_KPI_TARGET_CHANGE',
    SAM_SUBMITTED_RECOMMENDATION: 'SAM_SUBMITTED_RECOMMENDATION',
    PM_SUBMITTED_RECOMMENDATION_FEEDBACK: 'PM_SUBMITTED_RECOMMENDATION_FEEDBACK',
    INVALID_KPI: 'INVALID_KPI',
    OFF_TARGET_CIRCUIT: 'OFF_TARGET_CIRCUIT',
};

export const DEFAULT_NOTIFICATION_SCHEDULE = {
    STARTING_TIME: '09:00:00',
    ENDING_TIME: '17:00:00',
};

export const WEEK_DAYS = {
    SUNDAY: 'SUNDAY',
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
};

export const NOTIFICATIONS_REFRESH_INTERVAL = 5 * 60 * 1000; // Every 5 minutes

export const PHONE_VERIFICATION_CUSTOM_ERRORS = {
    CODE_ALREADY_VERIFIED: 'ns.phone_verification.code_already_verified',
    CODE_ALREADY_RESENT: 'ns.phone_verification.code_already_resent',
    CONCURRENT_SAME_NUMBER: 'ns.phone_verification.concurrent_verifications',
    WRONG_OTC: 'ns.phone_verification.wrong_code',
    WRONG_REQUEST_ID: 'ns.phone_verification.wrong_request_id',
};

export const JIRA_ISSUE_COLLECTOR_URL =
    'https://syensqo.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=f250c9eb';
export const JIRA_BUTTON_ID = 'atlwdg-trigger';

export const JIRA_SCRIPT_ID = 'JIRA_ISSUE_COLLECTOR';
