// @flow strict

export const MicroFrontEnds = {
    reportGenerationSystem: {
        development: 'http://localhost:3000',
        dev: 'https://rgs-fe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://rgs-fe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://rgs-fe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    plantConfiguration: {
        development: 'http://localhost:3001',
        dev: 'https://plant-configuration-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://plant-configuration-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://plant-configuration-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    platformStatus: {
        development: 'http://localhost:3003',
        dev: 'https://platform-status-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://platform-status-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://platform-status-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    platformAuthenticationLogin: {
        development: 'http://localhost:3006',
        dev: 'https://platform-authentication-login-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging:
            'https://platform-authentication-login-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production:
            'https://platform-authentication-login-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    plantDashboard: {
        development: 'http://localhost:3004',
        dev: 'https://plant-dashboard-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://plant-dashboard-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://plant-dashboard-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    cyanex: {
        development: 'http://localhost:4200',
        dev: 'https://cyanex-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://cyanex-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://cyanex-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    reagentsManagement: {
        development: 'http://localhost:4301',
        dev: 'https://reagents-management-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://reagents-management-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://reagents-management-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
    minChem: {
        development: 'http://localhost:4205',
        dev: 'https://minchem-frontend-mfe-dot-dev-minchem-solvextract.appspot.com',
        staging: 'https://minchem-frontend-mfe-dot-pprd-minchem-solvextract.appspot.com',
        production: 'https://minchem-frontend-mfe-dot-prod-minchem-solvextract.appspot.com',
    }[process.env.NODE_ENV || 'development'],
};
